import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext, useContext } from '../Context';
import i18next from '../i18n';

const Search = ({ colorOfSearch }) => {
    const [isSearchOpen, setSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestedPages, setSuggestedPages] = useState([]);
    const inputRef = useRef(null);
    const history = useNavigate();

    const { setIsMenuOpen } = useContext(MainContext);

    const handleSearchInputChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // İlgili dilin sayfalar dizisi
        const pages = i18next.language === 'tr' ? trPages : enPages;

        // Sadece sayfa adlarını içeren dizi
        const pageNames = pages.map((page) => page.name);

        const filteredResults = pageNames.filter((page) =>
            page.toLowerCase().includes(query.toLowerCase())
        );

        setSuggestedPages(filteredResults);
    };

    const handleSuggestionClick = (index) => {
        const selectedPage = suggestedPages[index];

        const pages = i18next.language === 'tr' ? trPages : enPages;

        const selectedPageInfo = pages.find((page) => page.name === selectedPage);

        if (selectedPageInfo) {
            const path = selectedPageInfo.path;
            history(path);
            setSearchOpen(false);
            setSearchQuery('');
            setIsMenuOpen(false);
        }
    };

    const handleSearchToggle = () => {
        setSearchOpen(!isSearchOpen);

        if (!isSearchOpen) {
            setSuggestedPages(i18next.language === 'en' ? enPages : trPages);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setSearchOpen(false);
                setSearchQuery('');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const trPages = [
        { name: 'Hakkımızda', path: 'about' },
        { name: 'İletişim', path: 'contact' },
        { name: 'Odalar', path: 'room' },
        { name: 'Restoran', path: 'restaurant' },
        { name: 'Sürdürülebilirlik', path: 'sustainability' },
        { name: 'Özel Etkinlikler', path: 'privateevents' },
        { name: 'Gizlilik Politikası', path: 'privacypolicy' },
        { name: 'Kullanım Şartları', path: 'termsofuse' },
        { name: 'Çerez Politikası', path: 'cookiepolicy' },
        { name: 'Bungalov', path: 'room?scrollTo=imageroom-0' },
        { name: 'Jakuzi', path: 'room?scrollTo=imageroom-0' },

        { name: 'Deluxe', path: 'room?scrollTo=imageroom-1' },
        { name: 'Dublex', path: 'room?scrollTo=imageroom-1' },

        { name: 'Süit', path: 'room?scrollTo=imageroom-2' },
        { name: 'Eko', path: 'room?scrollTo=imageroom-3' },
        { name: 'Ekonomik', path: 'room?scrollTo=imageroom-3' },
        { name: 'Standart', path: 'room?scrollTo=imageroom-4' },
        { name: 'Havuz', path: 'room?scrollTo=imageroom-4' },
        { name: 'Balkon', path: 'room?scrollTo=imageroom-5' },
    ];

    const enPages = [
        { name: 'About', path: 'about' },
        { name: 'Contact', path: 'contact' },
        { name: 'Room', path: 'room' },
        { name: 'Restaurant', path: 'restaurant' },
        { name: 'Sustainability', path: 'sustainability' },
        { name: 'Private Events', path: 'privateevents' },
        { name: 'Privacy Policy', path: 'privacypolicy' },
        { name: 'Terms of Use', path: 'termsofuse' },
        { name: 'Cookie Policy', path: 'cookiepolicy' },
        { name: 'Bungalow', path: 'room?scrollTo=imageroom-0' },
        { name: 'Jacuzzi', path: 'room?scrollTo=imageroom-0' },
        { name: 'Deluxe', path: 'room?scrollTo=imageroom-1' },
        { name: 'Duplex', path: 'room?scrollTo=imageroom-1' },
        { name: 'Suite', path: 'room?scrollTo=imageroom-2' },
        { name: 'Eco', path: 'room?scrollTo=imageroom-3' },
        { name: 'Economic', path: 'room?scrollTo=imageroom-3' },
        { name: 'Standart', path: 'room?scrollTo=imageroom-4' },
        { name: 'Pool', path: 'room?scrollTo=imageroom-4' },
        { name: 'Balcony', path: 'room?scrollTo=imageroom-5' },
    ];

    return (
        <div className='flex text-[#303030]'>
            <div alt="Search Icon" onClick={handleSearchToggle}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M9.57682 15.9167C11.4102 15.9167 12.9935 15.25 14.2435 14.1667L18.8268 18.75L19.4102 18.1667L14.8268 13.5C15.9102 12.25 16.5768 10.5833 16.5768 8.83333C16.5768 4.91667 13.4102 1.75 9.49349 1.75C5.57682 1.75 2.41016 4.91667 2.41016 8.83333C2.49349 12.6667 5.66016 15.9167 9.57682 15.9167ZM9.57682 2.58333C12.9935 2.58333 15.8268 5.41667 15.8268 8.83333C15.8268 12.25 12.9935 15.0833 9.57682 15.0833C6.16016 15.0833 3.32682 12.25 3.32682 8.83333C3.32682 5.33333 6.16016 2.58333 9.57682 2.58333Z" fill={colorOfSearch} />
                </svg>
            </div>
            <div className="absolute ml-8" ref={inputRef}>
                {isSearchOpen && (
                    <div className="">
                        <input
                            type="text"
                            placeholder='...'
                            className="border pl-2 w-[150px] bg-[#F7F7F7]"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                        />
                        {searchQuery.length > 0 && suggestedPages.length > 0 && (
                            <div className='bg-[#F7F7F7] overflow-auto max-h-60 p-2'>
                                <ul>
                                    {suggestedPages.map((page, index) => (
                                        <li key={index} onClick={() => { handleSuggestionClick(index); }} className="italic text-gray-400">
                                            {page}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Search;