import React, { useEffect, useState } from 'react';

import sust0101 from '../assets/images/sustainability/01-01-sustainability.webp';
import sust0201 from '../assets/images/sustainability/02-01-sustainability.webp';
import sust0202 from '../assets/images/sustainability/02-02-sustainability.webp';
import sust0203 from '../assets/images/sustainability/02-03-sustainability.webp';
import sust0301 from '../assets/images/sustainability/03-01-sustainability.webp';
import sust0401 from '../assets/images/sustainability/04-01-sustainability.webp';
import sust0402 from '../assets/images/sustainability/04-02-sustainability.webp';
import sust0403 from '../assets/images/sustainability/04-03-sustainability.webp';
import sust0501 from '../assets/images/sustainability/05-01-sustainability.webp';
import sust0601 from '../assets/images/sustainability/06-01-sustainability.webp';
import i18next from 'i18next';

const Sustainability = () => {
    const [backgroundStyle, setBackgroundStyle] = useState();
    const { t } = i18next;
    useEffect(() => {
        const calculateImageHeight = () => {
            const imageElement = document.getElementById('image-1');
            const wrapper = document.getElementById('image-wrapper');

            if (imageElement && wrapper) {
                const imageRect = imageElement.getBoundingClientRect();
                const imageHeight = imageRect.height;
                const startY = 0;
                const endY = imageHeight;

                setBackgroundStyle({
                    background: `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`,
                });
            }
        };

        const handleResize = () => {
            calculateImageHeight();
        };

        // Sayfa yüklendiğinde ve pencere boyutu değiştiğinde hesaplamayı yap
        calculateImageHeight();
        window.addEventListener('resize', handleResize);

        // Resim yüklendiğinde de hesaplamayı yap
        const imageElement = document.getElementById('image-1');
        if (imageElement.complete) {
            calculateImageHeight();
        } else {
            imageElement.addEventListener('load', calculateImageHeight);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            imageElement.removeEventListener('load', calculateImageHeight);
        };
    }, []);

    return (
        <div className='text-[#303030] flex flex-col items-center'>

            {/* 1. Container */}
            <div id="image-wrapper" style={backgroundStyle} className='text-[#303030] h-auto w-screen flex justify-center desktop:text-center tablet:text-left phone:text-center pt-0 tablet:pt-44'>
                <div className='flex tablet:flex-row items-center phone:justify-center phone:flex-col overscreen:w-[106rem]'>
                    <div className='tablet:w-[40%] mr-6 tablet:mr-6 desktop:mr-10 phone:w-[100%] phone:m-0 phone:flex phone:justify-center'>
                        <img id='image-1' className="pl-0 desktop:pl-10 pt-0 desktop:pt-6 pb-0 tablet:pb-20 ph phone:m-0" src={sust0101} alt='view of hotel' />
                    </div>
                    <div className='w-[40%] desktop:w-[60%] tablet:w-[40%] phone:w-[90%] mr-0 tablet:mr-8'>
                        <h2 className='text-[28px] desktop:text-[32px] tracking-1 font-light mb-4 leading-[28px] mt-0 tablet:mt-0 phone:mt-8'>
                            {t("primusSustainability")}
                        </h2>
                        <h3 className='text-[18px] desktop:text-[20px] font-extralight mb-0 tablet:mb-28 leading-[16px] tracking-[1px]'>
                            {t("sustainabilityDescription")}
                        </h3>
                    </div>
                </div>
            </div>


            {/* 2. Container */}
            <div className='px-[24.5px] my-[40px] tablet:my-[60px] desktop:my-[100px]'>
                <div className="w-[22%] border-b-2 border-[#9E8772] mb-8 ml-auto" />

                <div className="flex w-full justify-center tablet:text-left phone:text-center mx-auto">
                    {/* 1. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={sust0201} alt='view of hotel' />
                    </div>

                    {/* 2. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={sust0202} alt='view of hotel' />
                    </div>

                    {/* 3. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={sust0203} alt='view of hotel' />
                    </div>
                </div>
                <div className="w-[22%] border-b-2 border-[#9E8772] mt-8 " />

                <div className='flex justify-center mt-10 desktop:w-[1000px] tablet:w-[700px] phone:w-[300px] mx-auto'>
                    <h3 className='text-[24px] font-light mb-8 leading-[24px] text-center tracking-[1px]'>
                        {t("greenOasis")}
                        <span className='text-[20px] font-extralight text-center'>
                            {" "}{t("stepInto")}                </span>
                    </h3>
                </div>
            </div>


            {/* 3. Container */}
            <div className="h-auto w-screen bg-[#1C242E] flex justify-center items-center">
                <div className='text-[#F7F7F7] tablet:p-8 phone:p-0 tablet:text-left phone:text-center overscreen:w-[106rem] '>
                    <div className='flex tablet:flex-row tablet:justify-between items-center phone:justify-center phone:flex-col'>
                        <div className='tablet:w-[40%] tablet:mr-10 phone:w-[100%] phone:m-0 phone:flex phone:justify-center'>
                            <img className="tablet:pt-6 pb-6 phone:pt-[30px] phone:px-[16px]" src={sust0301} alt='Sustainable Amenities' />
                        </div>
                        <div className='w-[90%] tablet:w-[60%] desktop:ml-10 desktop:pr-24 tablet:ml-0 tablet:pr-0'>
                            <h2 className='text-[38px] desktop:text-[44px] tracking-1 font-extralight mb-4 leading-[45px] tracking-[3px]'>
                                {t("amenities")}
                            </h2>
                            <p className='text-xl font-extralight mb-8 leading-[28px]'>
                                {t("discoverGreener")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* 4. Container */}
            <div className='px-[24.5px] my-[40px] tablet:my-[60px] desktop:my-[100px]'>
                <div className="w-[22%] border-b-2 border-[#9E8772] mb-8 " />
                <div className="flex w-full justify-center tablet:text-left phone:text-center mx-auto">
                    {/* 1. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={sust0401} alt='view of hotel' />
                    </div>

                    {/* 2. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={sust0402} alt='view of hotel' />
                    </div>

                    {/* 3. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={sust0403} alt='view of hotel' />
                    </div>
                </div>
                <div className="w-[22%] border-b-2 border-[#9E8772] mt-8 ml-auto" />
            </div>


            {/* 5. Container */}

            <div className="h-auto w-screen bg-[#F4EEEB] flex justify-center items-center">
                <div className='text-[#303030] tablet:p-8 phone:p-0 tablet:text-left phone:text-center overscreen:w-[106rem] '>
                    <div className='flex tablet:flex-row tablet:justify-between items-center phone:justify-center phone:flex-col-reverse'>

                        <div className='w-[90%] tablet:w-[60%] desktop:ml-10 tablet:ml-4 phone:ml-0 desktop:pr-24 tablet:pr-12 phone:pr-0 '>
                            <h2 className='text-[38px] desktop:text-[44px] tracking-1 font-extralight mb-4 leading-[45px] tracking-[3px]'>
                                {t("ecoStay")}
                            </h2>
                            <h3 className='text-xl font-extralight mb-8 leading-[28px]'>
                                {t("elevateStay")}
                            </h3>
                        </div>
                        <div className='tablet:w-[40%] tablet:mr-10 phone:w-[100%] phone:m-0 phone:flex phone: justify-center'>
                            <img className="tablet:pt-6 pb-6 phone:pt-[30px] phone:px-[16px]" src={sust0501} alt='Sustainable Amenities' />
                        </div>
                    </div>
                </div>
            </div>


            {/* 6. Container */}
            <div className="h-auto w-screen flex justify-center items-center">
                <div className='text-[#303030] tablet:p-8 phone:p-0 tablet:text-left phone:text-center overscreen:w-[106rem] '>
                    <div className='flex tablet:flex-row tablet:justify-between items-center phone:justify-center phone:flex-col'>
                        <div className='tablet:w-[40%] tablet:mr-10 phone:w-[100%] phone:m-0 pt-[50px] px-[15px] phone:flex phone:justify-center'>
                            <img className="desktop:border-[50px] phone:border-[30px] border-[#1C242E]" src={sust0601} alt='Sustainable Amenities' />
                        </div>
                        <div className='w-[90%] tablet:w-[60%] desktop:ml-10 desktop:pr-24 tablet:ml-0 tablet:pr-0'>
                            <h2 className='text-[38px] desktop:text-[44px] tracking-1 font-extralight mb-4 leading-[45px] tracking-[3px] mt-6'>
                                {t("exploreOur")}
                            </h2>
                            <p className='text-xl font-extralight leading-[28px]'>
                                {t("scanQR")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Sustainability;
