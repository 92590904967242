import React, { useEffect } from 'react'

import ContactForm from '../components/ContactForm'

import contact0101 from '../assets/images/contact/01-01-contact.webp'
import phone from '../assets/svg/08-icon-contact-phone.svg'
import mail from '../assets/svg/09-icon-contact-mail.svg'
import instagramico from '../assets/svg/10-icon-contact-instagram.svg'
import locationico from '../assets/svg/12-icon-contact-location.svg'
import i18next from '../i18n'
import { useLocation } from 'react-router-dom'


const Contact = () => {
  const { t } = i18next;

  // Koordinatlar:
  const coordinates = { lat: 38.28009715200282, lng: 26.370700621871492 };
  const location = useLocation();

  const handleAddressClick = () => {
    const mapUrl = `https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`;
    window.open(mapUrl, '_blank');
  };

  useEffect(() => {
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const scrollToElement = urlParams.get('scrollTo');
      if (scrollToElement) {
        const element = document.getElementById(scrollToElement);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 200);
  }, [location.pathname, location.search]);

  return (
    <div id='contactlink'>
      <div className=' flex justify-center'>

        {/* 1. Container */}
        <div className='mt-[140px] desktop:mt-[208px] px-[50px] flex flex-col-reverse tablet:flex-row overscreen:w-[106rem]'>
          {/* 1. Left Side */}
          <div className='text-center mx-auto'>
            <h2 className='text-[38px] desktop:text-[52px] font-thin leading-[53.76px] tracking-[3px] phone:pt-20 tablet:pt-0'>{t("contactDetails")}</h2>
            <a href="tel:+905323055095" className='flex flex-col items-center mt-[20px] desktop:mt-[100px] tablet:mt-[30px] phone:mt-[20px]'>
              <img src={phone} alt="Phone" />
              <h3 className='text-[22px] desktop:text-[28px] font-light leading-[35px] tracking-[2.34px] mt-6 pb-4'>
                {t("contactPhoneNumber")}<br />+90 (532) 305 50 95
              </h3>
            </a>
            <a href="mailto:info@primusotel.com">
              <div className='flex flex-col items-center mt-8'>
                <img src={mail} alt="E-Mail" />
                <h3 className='cursor-pointer text-[22px] desktop:text-[28px] font-light leading-[35px] tracking-[2.34px] mt-6 pb-4'>
                  {t("contactEmail")}<br />info@primusotel.com
                </h3>
              </div>
            </a>
            <a href="https://www.instagram.com/primusotel/">
              <div className='flex flex-col items-center mt-8'>
                <img src={instagramico} alt="Instagrem" />
                <h3 className='cursor-pointer text-[22px] desktop:text-[28px] font-light leading-[35px] tracking-[2.34px] mt-6 pb-4'>
                  Instagram<br />@primusotel
                </h3>
              </div>
            </a>
            <div className='flex flex-col items-center mt-8 cursor-pointer tablet:w-[80%] desktop:w-[60%] mx-auto' onClick={handleAddressClick}>
              <img src={locationico} alt="Address" />
              <h3 className='text-[22px] desktop:text-[28px] font-light leading-[35px] tracking-[2.34px] mt-6'>
                {t("address1")}
              </h3>
              <ul className="list-disc">
                <li>
                  <h3 className='list-inside text-[22px] desktop:text-[28px] font-light leading-[30px] tracking-[1px] mt-2'>Alaçatı Mah. 12080 Sok. No: 24 Çeşme, İzmir</h3>
                </li>
                <li>
                  <h3 className='list-inside text-[22px] desktop:text-[28px] font-light leading-[20px] tracking-[1px] mt-2 pb-10'>Çamlık <span className='text-[18px] desktop:text-[24px]'>({t("comingSoonContact")})</span></h3>
                </li>
              </ul>
            </div>
          </div>
          {/* 2. Right Side */}
          <div>
            <img className="h-full object-cover" src={contact0101} alt='Comminication' />
          </div>
        </div>
      </div>

      {/* 2. Container */}
      <div id="contactformref">
        <ContactForm />
      </div>

    </div >
  )
}

export default Contact