import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            // -------- Home -------- //
            menu: 'Menu',
            language1: 'Turkish',
            language2: 'English',
            embraceSunshine: "Embrace the Sunshine and Experience the Distinctive Primus Elegance.",
            welcomePrimus: "Welcome to Primus, where every moment is a celebration of luxury and comfort. Nestled in the heart of Alaçatı, İzmir, our boutique hotel invites you to bask in the warmth of the sun and immerse yourself in the unparalleled charm of the Primus style.",
            paradiseEye: "A Paradise For the Discerning Eye",
            primusIsMore: "Primus is more than just a hotel; it's an oasis for those with a discerning eye for beauty. The harmonious blend of design and nature creates a captivating paradise that transcends ordinary escapes. Whether you're savoring moments of serenity in our lush gardens or appreciating the artistry within our meticulously adorned rooms, Primus offers a visual feast that leaves a lasting impression.",
            harmonyOf: "A Harmony of Luxury and Nature",
            experiencePerfect: "Experience the perfect blend of opulence and natural beauty at Primus Otel, where lush surroundings seamlessly complement luxurious comforts.",
            journeyDiscover1: "Journeys and Discoveries Tailored ",
            journeyDiscover2: "for the Inquisitive Explorer",
            empoweringJourney: "Empowering Your Journey:",
            empoweringJourney2: "Primus Otel's Exclusive Electric Vehicle Charging Stations",
            atPrimus: "At Primus, we're committed to a future of sustainable travel. Experience the convenience and eco-friendly luxury with our exclusive electric vehicle charging stations.",
            valuedGuest: " As our valued guest, enjoy the ease of recharging your electric vehicle on-site, showcasing our dedication to both your comfort and the environment. Power up for the next leg of your journey while contributing to a greener tomorrow – another way Primus makes your stay extraordinary.",

            // -------- Home.js Container 4 -------- //
            imageDataContainer4_1_Title: "PRIMUS BAR",
            imageDataContainer4_1_Subtitle: "A Sophisticated Libation Sanctuary",
            imageDataContainer4_1_Description: "Indulge your senses at Primus Bar, an exquisite destination within our boutique hotel that transcends the ordinary, offering a refined escape for the discerning connoisseur. Nestled in the heart of Alaçatı, İzmir, Primus Bar is more than just a watering hole; it's a sophisticated sanctuary where craftsmanship meets conviviality.",
            imageDataContainer4_2_Title: "POOL",
            imageDataContainer4_2_Subtitle: "Dive Into Tranquility: Primus Pool, A Refreshing Oasis",
            imageDataContainer4_2_Description: "Immerse yourself in the epitome of relaxation at Primus Pool, where crystal-clear waters meet chic surroundings to create an idyllic escape within our boutique hotel in Alaçatı, İzmir. Far beyond a mere pool, this aquatic oasis is a haven designed to elevate your senses and redefine the art of leisure.",
            imageDataContainer4_3_Title: "NATURE'S EMBRACE",
            imageDataContainer4_3_Subtitle: "Where Nature Inspires Design",
            imageDataContainer4_3_Description: "Step into a world where every architectural detail is inspired by the surrounding beauty of Alaçatı. Primus is designed to embrace nature, inviting the outdoors in and creating a harmonious sanctuary for our guests. Our lush gardens, adorned with indigenous flora, provide a tranquil backdrop, offering a peaceful escape from the hustle and bustle of everyday life.",
            imageDataContainer4_4_Title: "GASTRONOMY",
            imageDataContainer4_4_Subtitle: "Culinary Revelry",
            imageDataContainer4_4_Description: "Embark on culinary odysseys at Primus, where exquisite dining takes center stage. From enchanting chef's creations to Michelin-inspired feasts in unexpected settings, immerse yourself in culinary spectacle curated by our virtuosos, elevating their art to extraordinary heights.",

            // -------- Home.js Container 6 -------- //
            imageDataContainer6_1_Title: "UNWIND IN UNPARALLELED LUXURY",
            imageDataContainer6_1_Subtitle: "Indulge in Bespoke Bliss: Our Private Bungalows, Each with a Personal Jacuzzi",
            imageDataContainer6_1_Description: "At Primus, we redefine the meaning of indulgence with our exclusive Jacuzzi Bungalows, where your unique experience takes center stage. Step into a haven of sophistication, where each bungalow is a sanctuary of tranquility boasting its own private Jacuzzi.",
            imageDataContainer6_2_Title: "VERDANT ELEGANCE",
            imageDataContainer6_2_Subtitle: "Exploring the Green Heart of Primus",
            imageDataContainer6_2_Description: "Discover Primus, a lush haven where nature's green symphony harmonizes with luxury. Adorned with carefully cultivated greenery, our hotel weaves a tale of vibrant beauty. Primus invites you to dive into a tranquil oasis where every moment is a refreshing connection with the natural world.",
            imageDataContainer6_3_Title: "ROOMS",
            imageDataContainer6_3_Subtitle: "Select the Room That Best Fits Your Preferences From Our Range of Options",
            imageDataContainer6_3_Description: "Explore our diverse range of room options and choose the one that aligns seamlessly with your preferences. Whether you desire a spacious suite with panoramic views or a cozy room nestled in tranquility, our selection ensures a personalized experience.",

            // -------- Menu Links -------- //
            primusH1: "PRİMUS",
            roomsAndSuites: 'Rooms and Suites',
            restaurant: 'Restaurant',
            privateEvents: 'Private Events',
            sustainability: 'Sustainability',
            aboutUs: 'About Us',
            contact: 'Contact',
            contact1: 'Send Us a Message',
            contact2: 'Get Inspired',
            new: 'New',
            comingSoon1: "Primus Otel | Çamlık",
            comingSoon: 'COMING SOON!',
            visitUs: 'Visit Us',
            menuHeader: "Menu",

            // -------- About -------- //
            about6thDescription: "Primus Otel extends a warm invitation to a year-round experience marked by pleasure, simplicity, elegance, and the creation of unforgettable memories. In this unique place that cherishes its local heritage and respects the beauty of nature, guests are invited to immerse themselves in an atmosphere that transcends the ordinary, offering a journey of refined indulgence.",
            about5thDescription: "In the evening, Primus Restaurant, nestled within our premises, beckons guests to a culinary journey. Our rich menu, a fusion of Aegean and global cuisines, promises delectable lunches and dinners, creating a gastronomic experience that complements the charm of our unique location.",
            about4thDescription: "During the day, guests can bask in the sun by our inviting pool, indulging in moments of relaxation. Our rich bar ensures a choice of refreshing beverages, allowing guests to unwind and savor the serene ambiance of Primus Otel.",
            aboutThirdDescription: "Boasting 27 elegantly styled rooms, Primus Otel harmoniously blends with the natural surroundings, offering a distinctive charm. Operating on a bed & breakfast concept, we take pride in curating a delightful beginning for our guests, starting their day with a sumptuous Aegean breakfast featuring locally sourced and homemade products.",
            aboutFirstDescription: 'Nestled in the heart of Alaçatı, the favored Aegean destination renowned for its historic stone houses, windsurfing-friendly beaches, and vibrant streets, Primus Otel offers an alluring retreat for those seeking a delightful holiday experience.',
            aboutSecondDescription: 'Strategically positioned just 5 minutes away from the town center yet secluded from the noise, our boutique hotel provides a serene escape. Ideally located 3 km from the captivating beaches, 80 km from the Izmir city center, and 89 km from Adnan Menderes Airport, we ensure convenience and tranquility for our guests.',
            discoverMore: 'Discover More',
            cocktail: 'Exploring the Green Heart of Primus',
            elevatePrivateEvents: "Explore Primus, a lush sanctuary where nature's green symphony harmonizes with luxury. Adorned with carefully cultivated greenery, our hotel weaves a narrative of vibrant beauty.",
            firesideBliss: 'Fireside Bliss',
            immerseFirePlace: 'Immerse yourself in the welcoming glow of our fireplace, where the crackling flames create a haven of warmth and relaxation.',
            timelessMoments: 'A Toast to Timeless Moments',
            sophisticatedAmbiance: "Step into the sophisticated ambiance of our hotel's bar, where luxury and relaxation converge to create an unparalleled experience for our discerning guests.",

            // -------- Cookie Policy -------- //
            cookiePolicyTitle: 'Cookie Policy for Primus Otel',
            cookiePolicyBody1: 'This Cookie Policy explains how Primus Otel uses cookies and similar technologies to enhance your experience while visiting our website [www.primusotel.com]. By using our website, you consent to the use of cookies in accordance with this policy. What are Cookies: Cookies are small text files that are stored on your device (computer, tablet, smartphone) when you visit a website. They are widely used to make websites work or improve their efficiency, as well as to provide information to the owners of the site.',
            cookiePolicyBody2: 'How We Use Cookies: Primus Otel uses cookies for various purposes, including but not limited to:',
            cookiePolicyListElement1: 'Analyzing website traffic and user behavior.',
            cookiePolicyListElement2: 'Customizing your experience on our website.',
            cookiePolicyListElement3: 'Improving our website based on your preferences.',
            typesOfCookies: 'Types of Cookies We Use:',
            cookieListElement1: 'Essential Cookies: These cookies are necessary for the proper functioning of our website. They enable you to navigate our site and use its features.',
            cookieListElement2: 'Analytical Cookies: These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.',
            cookieListElement3: 'Functional Cookies: These cookies allow us to remember choices you make and provide enhanced, more personalized features.',
            thirdParty1: "Third-Party Cookies: We may also use third-party cookies, such as Google Analytics, to analyze and optimize our website's performance. These cookies are subject to the respective privacy policies of these third parties. Managing Cookies: You can control and/or delete cookies as you wish. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. This may prevent you from taking full advantage of the website.",
            thirdParty2: "Changes to This Cookie Policy: Primus Otel reserves the right to update this Cookie Policy at any time. We encourage you to review this policy periodically for any changes. Continued use of our website after any modification constitutes your acceptance of the updated policy.",
            thirdParty3: "Contact Us: If you have any questions about our Cookie Policy, please contact us at [contact@primusotel.com].",

            // -------- Privacy Policy -------- //
            privacyPolicyPrimus: "Privacy Policy for Primus Otel",
            privacyPolicyP1: 'This Privacy Policy outlines how Primus Otel collects, uses, maintains, and discloses information collected from users (each, a "User") of the [www.primusotel.com] website ("Site"). This policy applies to the Site and all products and services offered by Primus Otel.',
            privacyPolicyP2: 'Personal Identification Information: We may collect personal identification information from Users in various ways, including but not limited to when Users visit our site, register on the site, place an order, fill out a form, respond to a survey, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number informations.',
            privacyPolicyP3: 'Non-personal Identification Information: We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer, and technical information about Users\' means of connection to our Site, such as the operating system and the Internet service providers utilized.',
            privacyPolicyP4: 'Web Browser Cookies: Our Site may use "cookies" to enhance User experience. Users\' web browsers place cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent. If they do so, note that some parts of the Site may not function properly.',
            privacyPolicyP5: 'How We Use Collected Information: Primus Otel may collect and use Users\' personal information for the following purposes:',
            privacyPolicyLi1: 'To improve customer service.',
            privacyPolicyLi2: 'To personalize user experience.',
            privacyPolicyLi3: 'To process payments.',
            privacyPolicyLi4: 'To send periodic emails.',
            privacyPolicyP6: 'How We Protect Your Information: We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Site.',
            privacyPolicyP7: 'Sharing Your Personal Information: We do not sell, trade, or rent Users\' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.',
            privacyPolicyP8: 'Changes to This Privacy Policy: Primus Otel has the discretion to update this privacy policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.',
            privacyPolicyP9: 'Your Acceptance of These Terms: By using this Site, you signify your acceptance of this policy.',

            // -------- Private Events-------- //
            welcomeExclusive: "Welcome to Exclusive Gatherings",
            primusInvite: "At Primus, we invite you to transform your special moments into unforgettable experiences by hosting private events within the luxurious ambiance of our boutique hotel. Whether it's a corporate meeting, a milestone celebration, or an intimate gathering, our dedicated team is here to ensure that every detail is meticulously crafted for a seamless and memorable event.",
            memories: "Impeccable Service, Unforgettable Memories",
            beyond: "At Primus, our commitment goes beyond providing elegant spaces and delectable cuisine. Our team is devoted to delivering impeccable service, ensuring that your event unfolds seamlessly. From meticulous planning to execution, we strive to create an atmosphere where you can focus on creating lasting memories while we take care of every detail.",
            yourVision: "Your Vision, Our Expertise",
            collaborate: "Collaborate with our event specialists to bring your vision to life. Whether you have a specific theme in mind or need assistance in conceptualizing your event, Primus is here to turn your ideas into reality. Our expertise, combined with your unique preferences, ensures that your private event at Primus is a true reflection of your style and purpose.",

            // -------- Restaurant-------- //
            savor: "Savor the True Nature of Primus",
            worldOfExquisite: "Primus, a world of exquisite elegance situated against the charming backdrop of Alaçatı, encourages you to relish life's little pleasures. Take in the vivid colors of our surroundings, where delectable food and first-rate service come together to transform your stay and turn every minute at Primus into an amazing voyage of sophistication and joy.",
            restaurantHours: "Restaurant Hours:",
            primusRestaurant: "Primus Restaurant",
            culinary: "Culinary Symphony at Primus Restaurant",
            experience: "Experience a harmonious blend of flavors at Primus Restaurant, where the rich tapestry of Aegean and global cuisines unfolds. Our restaurant, nestled within the heart of Primus, is a culinary sanctuary designed for those with discerning palates. Immerse yourself in an atmosphere where every dish is a masterpiece, meticulously crafted using locally sourced, fresh ingredients. From vibrant lunches to intimate dinners, Primus Restaurant offers a diverse menu that caters to varied tastes. Come and indulge in a gastronomic journey, where each bite is a celebration of culinary artistry and a reflection of our commitment to extraordinary dining.",
            savorRefresh: "Savor Refreshing",
            momentsPoolside: "Moments Poolside",
            lounge: "Lounge in leisure and enjoy your favorite drinks by the poolside at Primus. Let the soothing ambiance and picturesque surroundings elevate your experience, creating the perfect backdrop for relaxation and delightful indulgence. Whether it's a delicious cocktail or a refreshing mocktail, our poolside setting transforms every sip into a moment of pure bliss. Cheers to tranquil moments and the pleasure of unwinding in style at Primus.",
            raiseToast: "Raise a Toast to Sophistication at Primus Bar",
            escapeToRealm: "Escape to a realm of refined libation at Primus Bar, where each sip tells a tale of craftsmanship and elegance. Nestled within our boutique hotel, the bar is a haven for those who appreciate the art of mixology. Immerse yourself in an ambiance that seamlessly combines modern chic with a touch of glamour. Our expert mixologists curate an exceptional selection of cocktails, ensuring each drink is a celebration in itself. Whether you're unwinding after a day of exploration or seeking a lively night out, Primus Bar invites you to savor the extraordinary in every sip.",

            // -------- Room-------- //
            roomsGuide: "Rooms, Suites and Bungalows",
            roomDescription: "At Primus, our rooms and suites embody the pinnacle of luxury infused with the captivating essence of our locale. Experience the height of sophistication in accommodations that seamlessly blend comfort and style. Complete with large floor-to-ceiling windows, these rooms offer breathtaking views that showcase the charm of the surroundings.",
            jacuzzi: "Bungalows with Jacuzzi",
            bespoke: "Indulge in Bespoke Bliss: Our Private Bungalows, Each with Enchanting Gardens and a Personal Jacuzzi",
            redefine: "At Primus, we redefine the meaning of indulgence with our exclusive Jacuzzi Bungalows, where your unique experience takes center stage. Step into a haven of sophistication, where each bungalow is a sanctuary of tranquility boasting its own private Jacuzzi and enchanting garden.",
            bungalov1: "1. Bungalow with Jacuzzi Without Garden: Description: Cozy bungalow with a private Jacuzzi and no specific view. Size: 20 m²",
            bungalov2: "2. Bungalow with Jacuzzi and Garden: Description: Inviting bungalow with a private Jacuzzi and a charming private garden. Size: 20 m²",
            bungalov3: "1. Bungalow with Jacuzzi Without Garden: Description: Cozy bungalow with a private Jacuzzi and no specific view. Size: 20 m²",
            bungalov4: "2. Bungalow with Jacuzzi and Garden: Description: Inviting bungalow with a private Jacuzzi and a charming private garden. Size: 20 m²",

            // -------- Sustainability-------- //
            primusSustainability: "Sustainability at Primus: A Greener Tomorrow",
            sustainabilityDescription: "At Primus, we champion sustainability as a fundamental pillar of our ethos. From recycled amenities packaging to innovative practices like our 'Change My Sheets' card system, every stay with us reflects our commitment to minimizing environmental impact. Join us in embracing a greener tomorrow, where luxury meets responsibility.",
            greenOasis: "Welcome to Primus Otel's Green Oasis:",
            stepInto: "As you step into our sustainable haven, lush foliage surrounds you, whispering tales of our commitment to nature. Our entrance, adorned with thriving plants, symbolizes our dedication to creating a green sanctuary. Join us in this vibrant space, where every leaf tells a story of sustainability, and experience a stay that harmonizes with the beauty of the natural world.",
            amenities: "Sustainable Amenities: A Greener Approach to Daily Luxuries",
            discoverGreener: "Discover a Greener Touch: At Primus Otel, we prioritize sustainability in every detail. Our soaps, shampoos, and hair conditioners come in recycled packaging, reflecting our commitment to reducing environmental impact without compromising on luxury. Small choices can make a big difference, and at Primus, we believe in offering you a stay that is not just exceptional but also environmentally conscious.",
            ecoStay: "Eco-Conscious Stay: Primus Otel's Commitment to Sustainability",
            elevateStay: "Elevate Your Stay, Preserve Our Planet: Primus Otel is dedicated to sustainability, exemplified by our card system. When you leave the 'Change My Sheets' card on your bed, we ensure a fresh set without unnecessary washing, minimizing chemical use and promoting a more eco-friendly environment. Join us in our commitment to a greener tomorrow, one thoughtful choice at a time.",
            exploreOur: "Explore Our Commitment to Sustainability:",
            scanQR: "Scan the QR code to access our official Sustainability Certificate. Dive into the details of Primus's eco-friendly initiatives as we strive towards a greener, more sustainable future. Your journey to responsible hospitality begins here.",

            // -------- Terms of Use Agreements-------- //
            termsOfUse: "Terms of Use Agreement for Primus Otel",
            welcomePrimusWebsite: " Welcome to the Primus Otel website. By accessing this website, you agree to comply with and be bound by the following terms of use. If you disagree with any part of these terms, please do not use our website.",
            useOfWebsite: "Use of the Website: The content of this website is for your general information and use only. It is subject to change without notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose.",
            intellectualProperty: "Intellectual Property: This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited without prior written consent.",
            responsibilities: "User Responsibilities: Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It is your responsibility to ensure that any products, services, or information available through this website meet your specific requirements.",
            thirdParty: "Third-Party Links: From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).",
            privacy: "Privacy: Your use of this website is subject to our Privacy Policy. Please review our Privacy Policy, which also governs the website and informs users of our data collection practices.",
            disclaimer: "Disclaimer: The content of the pages of this website is for your general information and use only. It is subject to change without notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose.",
            changesTermsOf: "Changes to Terms of Use: Primus Otel reserves the right to change these terms of use at any time by updating this page. You should check this page periodically to ensure that you are aware of any changes.",
            changesTermsOfLast: "By using this website, you acknowledge that such use is at your sole risk, and you accept the terms and conditions outlined in this Terms of Use Agreement. If you have any questions or concerns, please contact us at [contact@primusotel.com].",

            // -------- Contact-------- //
            contactDetails: "Contact Us",
            phoneNumber: "Telefon",
            email: "E-posta: info@primusotel.com",
            contactPhoneNumber: "Phone Number",
            contactEmail: "Email Address",
            address1: "Address",
            comingSoonContact: "Coming Soon",

            // -------- ContactForm-------- //
            formName: "Name is required",
            formEmail: "Email is required",
            formEmailError: "Please enter a valid email address",
            formMessage: "Message is required",
            formMessageError: "Message should be 500 characters or less",
            formToast: "Mail is sending to us...",

            // -------- Footer -------- //
            getInspired: "Get inspired",
            toReceiveUpdates: "Send us a message to get updates on special experiences, events, new destinations and more.",
            footerContact: "Contact",
            explore: "EXPLORE",
            connect: "CONNECT",
            links: "LINKS",
            termsOfUseFooter: "Terms of Use",
            privacyPolicy2: "Privacy Policy",
            cookiePolicy: "Cookie Policy",
            copyright: "Copyright 2024, Codelps",
            receiveFooter: "For personalized events and exciting destinations, connect with us directly. Call now to start creating unforgettable moments with Primus.",

            // -------- Menu -------- //
            english: "English",

            // -------- HomeCarousel -------- //
            instagramText_1: "Follow Us: Be informed about events, opportunities and latest developments! ",
            instagramText_2: "@primusotel",

            // -------- Room.js-------- //
            roomCarouselRoom1Subtitle: "Bungalows with Jacuzzi",
            roomCarouselRoom2Subtitle: "Deluxe Duplex",
            roomCarouselRoom3Subtitle: "Deluxe Suite",
            roomCarouselRoom4Subtitle: "Eco Standard Room",
            roomCarouselRoom5Subtitle: "Standard room with pool view",
            roomCarouselRoom6Subtitle: "Pool View Room with Balcony",
            roomCarouselRoom1Description: "Indulge in pure luxury with our Bungalow featuring a private Jacuzzi and enchanting garden. Experience a secluded retreat where comfort meets tranquility, creating an oasis of relaxation just for you.",
            roomCarouselRoom2Description: "Elevate your stay with our Deluxe Duplex Room, a sophisticated two-level haven that exudes modern elegance. Enjoy the spacious design and thoughtful amenities, ensuring a stylish and comfortable retreat.",
            roomCarouselRoom3Description: "Unwind in style with our Deluxe Suite, where luxury meets indulgence. Immerse yourself in a soothing bath in your private bathtub, and let the refined ambiance of the suite enhance your stay to the fullest.",
            roomCarouselRoom4Description: "Experience an economically conscious stay in our Eco Standard Room. Meticulously crafted with eco-friendly features, this room offers a comfortable and sustainable retreat, ensuring both environmental responsibility and quality are at the forefront of your stay",
            roomCarouselRoom5Description: "Capture picturesque views from our Standard Room with Pool View. Immerse yourself in the serenity of the surroundings while enjoying the comfort and convenience of our well-appointed standard accommodations.",
            roomCarouselRoom6Description: "Experience sophistication in our Pool View Room with Balcony. This thoughtfully designed space includes a charming balcony, a loft with a French bed and a single bed, ensuring a comfortable and stylish retreat. Immerse yourself in elegance and comfort, where every detail enhances your stay.",
            exploreText: "Explore",
            room1CardDescription1: "Indulge in Bespoke Bliss: Our Private Bungalows, Each with Enchanting Gardens and a Personal Jacuzzi",
            room1CardDescription2: "At Primus, we redefine the meaning of indulgence with our exclusive Jacuzzi Bungalows, where your unique experience takes center stage. Step into a haven of sophistication, where each bungalow is a sanctuary of tranquility boasting its own private Jacuzzi and enchanting garden.",
            room1CardDescription3: "1. Bungalow with Jacuzzi Without Garden: Description: Cozy bungalow with a  private Jacuzzi and no specific view. Size: 20 m²",
            room1CardDescription4: "2. Bungalow with Jacuzzi and Garden: Description: Inviting bungalow with a private Jacuzzi and a charming private garden. Size: 20 m²",

            // -------- RoomCard.js-------- //
            room1CardTitle: "Deluxe Duplex",
            room1CardParagraph_one: "Elevate your stay with our Deluxe Duplex, a sophisticated two-level haven that exudes modern elegance. Enjoy the spacious design and thoughtful amenities, ensuring a stylish and comfortable retreat.",
            room1CardParagraph_two: "Description: Spacious two-floor suite featuring a French bed on the upper floor, a foldable sofa on the lower floor (transforms into a bed for 2 people), 2 bathrooms, 2 TVs, and 2 air conditioners.",
            room2CardTitle: "Deluxe Suite",
            room2CardParagraph_one: "Unwind in style with our Deluxe Suite, where luxury meets indulgence. Immerse yourself in a soothing bath in your private bathtub, and let the refined ambiance of the suite enhance your stay to the fullest.",
            room2CardParagraph_two: "Description: Elegant suite with a French balcony, bathtub, and a view of the pool. | Location: Ground floor | Size: 30 m² |",
            room3CardTitle: "Eco Standard Room",
            room3CardParagraph_one: "Experience an economically conscious stay in our Eco Standard Room. Meticulously crafted with eco-friendly features, this room offers a comfortable and sustainable retreat, ensuring both environmental responsibility and quality are at the forefront of your stay",
            room3CardParagraph_two: "Description: Comfortable room with either twin beds or a double bed. | Size: 15 m² |",
            room4CardTitle: "Standard Room with Pool View",
            room4CardParagraph_one: "Capture picturesque views from our Standard Room with Pool View. Immerse yourself in the serenity of the surroundings while enjoying the comfort and convenience of our well-appointed standard accommodations.",
            room4CardParagraph_two: "1. Garden Floor Room with Pool View: | Location: -1 floor | Size: 20 m² | Bed: French bed (double bed) |",
            room4CardParagraph_three: "2. French Balcony Room with Pool View: | Location: Ground floor | Size: 20 m² | Bed: French bed |",
            room5CardTitle: "Pool View Room with Balcony",
            room5CardParagraph_one: "Experience sophistication in our Pool View Room with Balcony. This thoughtfully designed space includes a charming balcony, a loft with a French bed and a single bed, ensuring a comfortable and stylish retreat. Immerse yourself in elegance and comfort, where every detail enhances your stay.",
            room5CardParagraph_two: "Description: Comfortable room with balcony and pool view. | Location: 1st Floor | Bed: 1 french bed and single bed on the loft floor | Size: 22 m²",

            // -------- ContactForm.js-------- //
            contanctFormTitle: "Send Us a Message",
            contanctFormName: "Name",
            contanctFormMail: "E-Mail",
            contanctFormMessage: "Message",
            contanctFormSend: "Send",

            // -------- NoMatch -------- //
            error404: "404 - Page Not Found",
            errorPageText: "The page you are looking for could not be found.",

            // -------- RoomCard -------- //
            card1Title: "Deluxe Duplex",
            card1ParagraphOne: "Elevate your stay with our Deluxe Duplex, a sophisticated two-level haven that exudes modern elegance. Enjoy the spacious design and thoughtful amenities, ensuring a stylish and comfortable retreat.",
            card1ParagraphTwo: "Description: Spacious two-floor suite featuring a French bed on the upper floor, a foldable sofa on the lower floor (transforms into a bed for 2 people), 2 bathrooms, 2 TVs, and 2 air conditioners.",
            card2Title: "Deluxe Suite",
            card2ParagraphOne: "Unwind in style with our Deluxe Suite, where luxury meets indulgence. Immerse yourself in a soothing bath in your private bathtub, and let the refined ambiance of the suite enhance your stay to the fullest.",
            card2ParagraphTwo: "Description: Elegant suite with a French balcony, bathtub, and a view of the pool. | Location: Ground floor | Size: 30 m² |",
            card3Title: "Eco Standard Room",
            card3ParagraphOne: "Experience an economically conscious stay in our Eco Standard Room. Meticulously crafted with eco-friendly features, this room offers a comfortable and sustainable retreat, ensuring both environmental responsibility and quality are at the forefront of your stay",
            card3ParagraphTwo: "Description: Comfortable room with either twin beds or a double bed. Size: 15 m²",
            card4Title: "Standard Room with Pool View",
            card4ParagraphOne: "Capture picturesque views from our Standard Room with Pool View. Immerse yourself in the serenity of the surroundings while enjoying the comfort and convenience of our well-appointed standard accommodations.",
            card4ParagraphTwo: "1. Garden Floor Room with Pool View: | Location: -1 floor | Size: 20 m² | Bed: French bed (double bed) |",
            card4ParagraphThree: "2. French Balcony Room with Pool View: | Location: Ground floor | Size: 20 m² | Bed: French bed |",
        }
    },
    tr: {
        translation: {

            // -------- Ana Sayfa -------- //
            menu: 'Menü',
            language1: 'Türkçe',
            language2: 'İngilizce',
            embraceSunshine: "Güneşin Keyfini Çıkarın ve Benzersiz Primus Zarafetini Deneyimleyin.",
            welcomePrimus: "Primus'a hoş geldiniz! Lüks ve konforun bir araya geldiği; İzmir, Alaçatı'nın kalbinde yer alan butik otelimiz, sizi güneşin sıcaklığında serinlemeye ve Primus’un eşsiz cazibesine kendinizi kaptırmaya davet ediyor.",
            paradiseEye: "Gören Gözler İçin Cennet",
            primusIsMore: "Primus, Alaçatı’da yer alan bir otelden çok daha fazlası. Tasarım ve doğanın uyumlu birleşimi, misafirlerimiz için sıradan bir tatilin ötesine geçen büyüleyici bir cennet yaratıyor. İster yemyeşil bahçelerimizde huzur dolu anların tadını çıkarın; ister titizlikle dekore edilmiş odalarımızın atmosferinde huzurla dinlenin, Primus size hafızalarınızda kalıcı bir iz bırakacak bir görsel şölen sunacak.",
            harmonyOf: "Lüks ve Doğanın Uyumu",
            experiencePerfect: "Yeşilin bir parçası olduğu ve lüksün her alanda tamamlayıcı bir element olduğu Primus Otel’de, konforun ve doğanın mükemmel birleşimini deneyimleyin.",
            journeyDiscover1: "Meraklılara Özel",
            journeyDiscover2: "Yolculuklar ve Keşifler",
            empoweringJourney: "Yolculuğunuzu Güçlendirin:",
            empoweringJourney2: "Primus Otel'in Özel Elektrikli Araç Şarj İstasyonları",
            atPrimus: "Primus olarak sürdürülebilir seyahatin geleceğine olan taahhüdümüzü sürdürüyoruz. Kendi elektrikli aracınızı şarj etmenin kolaylığını ve çevre dostu olmanın lüksünü, özel elektrikli araç şarj istasyonlarımızda deneyimleyin.",
            valuedGuest: "Değerli misafirimiz olarak, elektrikli aracınızı yerinde şarj etme kolaylığının keyfini çıkarın. Daha yeşil bir yarın için katkıda bulunurken yolculuğunuzun bir sonraki ayağı için gücünüze güç katın. Primus'un konaklamanızı olağanüstü kılmasının başka bir yolu.",

            // -------- Home.js Container 4 -------- //
            imageDataContainer4_1_Title: "PRIMUS BAR",
            imageDataContainer4_1_Subtitle: "Sofistike Bir Bar Deneyimi",
            imageDataContainer4_1_Description: "Primus Bar, seçkin damak tatlarına hitap eden, keyifli bir kaçış noktası… Alaçatı'nın kalbinde yer alan Primus’un içerisinde konumlanan Primus Bar, sıradanlığı aşan bir ustalığın lezzetle buluştuğu sofistike bir bar deneyimi değil sadece; aynı zamanda anlamlı sohbetlerin mutlu anlara karıştığı özel bir mekan.",
            imageDataContainer4_2_Title: "HAVUZ",
            imageDataContainer4_2_Subtitle: "Huzura Dalın: Primus Havuzu, Ferahlatıcı Bir Vaha",
            imageDataContainer4_2_Description: "Kendinizi Primus’un dingin havuzunda rahatlamaya bırakın… Kristal berraklığındaki bu havuz, tüm duyularınızı en üst seviyeye taşımak, size gerçek bir tatil deneyimi yaşatmak ve ‘keyif’ kelimesini yeniden tanımlamanız için tasarlanmış bir cennet adeta.",
            imageDataContainer4_3_Title: "DOĞANIN KUCAKLAYIŞI",
            imageDataContainer4_3_Subtitle: "Sofistike Bir Doğa Tapınağı",
            imageDataContainer4_3_Description: "Her mimari detayın Alaçatı'nın çevresel güzelliğinden ilham aldığı bir dünyaya adım atın. Primus, doğayı kucaklamak için tasarlanmış, dışarıyı içeriye davet eden ve misafirlerimiz için uyumlu bir sığınak oluşturan mimari detaylara sahip. Yerel bitki örtüsü ile süslenmiş yemyeşil bahçelerimiz, günlük hayatın koşuşturmasından sakin bir kaçış sunarak size huzurlu bir ortam sağlar.",
            imageDataContainer4_4_Title: "GASTRONOMİ",
            imageDataContainer4_4_Subtitle: "Mutfak Şöleni",
            imageDataContainer4_4_Description: "Primus'ta enfes yemeklerin ön planda olduğu, büyüleyici şef kreasyonlarından Michelin ilhamlı tabaklara kadar varan bir gastronomik yolculuğa davetlisiniz! Deneyimli şeflerimizin küratörlüğünü yaptığı, sanatlarını olağanüstü boyutlara taşıyan bu mutfak şölenine hazırlanın.",

            // -------- Home.js Container 6 -------- //
            imageDataContainer6_1_Title: "EŞSİZ LÜKSÜN İÇİNDE RAHATLAYIN",
            imageDataContainer6_1_Subtitle: "Kişiye Özel Mutluluk: Tamamen Size Ait Bir Jakuzisi Bulunan Bungalovlarımız",
            imageDataContainer6_1_Description: "Primus'ta lüksün anlamını yeniden tanımlıyoruz. Her bungalovun kendi özel jakuzisine sahip bir huzur sığınağı olduğu sofistike bir cennete adım atın.",
            imageDataContainer6_2_Title: "YEŞİL ZARAFET",
            imageDataContainer6_2_Subtitle: "Primus'un Yeşil Kalbini Keşfedin",
            imageDataContainer6_2_Description: "Doğanın yeşil senfonisinin lüksle uyum sağladığı yemyeşil bir sığınak olan Primus'u keşfedin. Özenle yetiştirilmiş yeşilliklerle bezenmiş otelimiz, canlı bir güzelliğin öyküsünü örmektedir. Primus sizi, her anın doğal dünyayla canlandırıcı bir bağlantı olduğu sakin bir vahaya dalmaya davet ediyor.",
            imageDataContainer6_3_Title: "ODALAR",
            imageDataContainer6_3_Subtitle: "Tercihlerinize En Uygun Odayı Seçin",
            imageDataContainer6_3_Description: "Çeşitli oda seçeneklerimizi keşfedin ve tercihlerinize kusursuz bir şekilde uyum sağlayanı seçin. İster panoramik manzaralı geniş bir süit ister huzur içinde konumlanmış rahat bir oda isteyin; tüm seçeneklerimiz size kişiselleştirilmiş bir deneyim sağlar.",

            // -------- Menü Bağlantıları -------- //
            roomsAndSuites: 'Odalar ve Suitler',
            restaurant: 'Restoran',
            privateEvents: 'Özel Etkinlikler',
            sustainability: 'Sürdürülebilirlik',
            aboutUs: 'Hakkımızda',
            primusH1: "PRİMUS",
            contact: 'İletişim',
            contact1: 'Bize Mesaj Gönderin',
            contact2: 'İlham Al',
            new: 'Yeni',
            comingSoon: 'YAKINDA!',
            visitUs: 'Bizi Ziyaret Edin',
            menuHeader: "Menü",

            // -------- Hakkımızda -------- //
            timelessMoments: 'Zamansız Anlara Kadeh Kaldırış',
            firesideBliss: "Şömine Keyfi",
            about6thDescription: "Primus Otel, yıl boyunca keyif, sadelik, zarafet ve unutulmaz anılar yaratmanın ön plana çıktığı bir deneyime sıcak bir davet sunuyor. Yerel mirasına değer veren ve doğanın güzelliğine saygı duyan bu eşsiz mekanda misafirler, kendilerini sıradanlığın ötesinde, rafine bir hoşgörü yolculuğu sunan bir atmosfere kaptırmaya davet ediliyor.",
            about5thDescription: "Akşam olduğunda, tesisimizin içinde bulunan Primus Restoranı, misafirleri bir mutfak yolculuğuna davet ediyor. Ege ve dünya mutfaklarının harmanlandığı zengin menümüz, lezzetli öğle ve akşam yemekleri vaat ediyor, benzersiz konumumuzun cazibesini tamamlayan bir gastronomik deneyim sunuyor.  ",
            about4thDescription: "Gün boyunca misafirlerimiz, havuzumuzun yanı başında güneşin tadını çıkarabilir, rahatlamanın keyfini sürebilirler. Zengin barımız, misafirlerimize ferahlatıcı içeceklerden seçim yapma imkanı sunar, böylece Primus Otel'in sakin atmosferinin tadını çıkarabilirler.",
            aboutThirdDescription: "Özenle dekore edilmiş 27 odaya sahip Primus Otel, doğal çevreye uyumlu atmosferiyle de kendine özgü bir dekorasyona sahip. Oda & kahvaltı konseptiyle işletilen otelimizde misafirlerimiz, güne yerel ve ev yapımı ürünlerden oluşan zengin bir Ege kahvaltısıyla başlıyor. Biz de bu keyifli bir başlangıcı onlara sunmanın gururunu yaşıyoruz!",
            aboutFirstDescription: "Tarihi taş evleri, rüzgar sörfüne uygun plajları ve hareketli sokaklarıyla Ege'nin gözde destinasyonu Alaçatı'nın kalbinde yer alan Primus Otel, keyifli bir tatil deneyimi arayanlar için cazip bir dinlenme ortamı sunuyor.",
            aboutSecondDescription: "Kasaba merkezine sadece 5 dakika uzaklıkta stratejik ancak gürültüden uzakta yer alan butik otelimiz size sakin bir kaçış noktası sunuyor. 3 km mesafedeki büyüleyici plajlara, 80 km mesafedeki İzmir şehir merkezine ve 89 km mesafedeki Adnan Menderes Havalimanı'na ideal bir konumda bulunuyor; misafirlerimize hem konfor hem de huzur sunmayı vaadediyoruz.",
            discoverMore: 'Daha Fazlasını Keşfedin',
            sophisticatedAmbiance: "Otelimizin barına adım atın, burada lüks ve rahatlamanın buluştuğu sofistike bir ortama girin; seçkin misafirlerimiz için eşsiz bir deneyim yaratıyor.",
            cocktail: "Primus'un Yeşil Kalbini Keşfedin",
            elevatePrivateEvents: "Doğanın yeşil senfonisinin lüksle uyum sağladığı yemyeşil bir sığınak olan Primus'u keşfedin. Özenle yetiştirilmiş yeşilliklerle bezenmiş otelimiz, canlı bir güzelliğin öyküsünü örmektedir.",
            immerseFirePlace: "Kendini, kıyamet gibi çıtırdayan alevlerin sıcaklığı ve rahatlığın bir limanını oluşturduğu, hoş bir ateşin ışıltısına kaptır.",
            connect: "BİZE ULAŞ",
            termsOfUseFooter: "Kullanım Şartları",

            // -------- Cookie Policy -------- //
            cookiePolicyTitle: 'Primus Otel İçin Çerez Politikası',
            cookiePolicyBody1: "Bu Çerez Politikası, Primus Otel'in web sitesini ziyaretiniz sırasında deneyiminizi geliştirmek için çerezler ve benzeri teknolojileri nasıl kullandığını açıklar [www.primusotel.com]. Web sitemizi kullanarak, bu politikaya uygun olarak çerezlerin kullanımına izin vermiş olursunuz. Çerez Nedir: Çerezler, bir web sitesini ziyaret ettiğinizde cihazınızda (bilgisayar, tablet, akıllı telefon) depolanan küçük metin dosyalarıdır. Web sitelerinin çalışmasını sağlamak veya verimliliğini artırmak, ayrıca sitenin sahiplerine bilgi sağlamak için yaygın olarak kullanılırlar.",
            cookiePolicyBody2: 'Çerezleri Nasıl Kullanıyoruz: Primus Otel, çeşitli amaçlarla çerezler kullanır, bunlar arasında ancak bunlarla sınırlı olmamak üzere şunları içerir:',
            cookiePolicyListElement1: 'Web sitesi trafiğini ve kullanıcı davranışlarını analiz etmek.',
            cookiePolicyListElement2: 'Web sitemizdeki deneyiminizi özelleştirmek.',
            cookiePolicyListElement3: 'Tercihlerinize göre web sitemizi iyileştirmek.',
            typesOfCookies: 'Kullandığımız Çerez Türleri:',
            cookieListElement1: 'Temel Çerezler: Bu çerezler, web sitemizin düzgün çalışması için gereklidir. Sitemizde gezinmenizi ve özelliklerinden yararlanmanızı sağlarlar.',
            cookieListElement2: 'Analitik Çerezler: Bu çerezler, ziyaretçilerin web sitemizle nasıl etkileşimde bulunduğunu anlamamıza yardımcı olur; bilgileri anonim olarak toplar ve raporlar.',
            cookieListElement3: 'İşlevsel Çerezler: Bu çerezler, yaptığınız seçimleri hatırlamamıza ve daha gelişmiş, daha kişiselleştirilmiş özellikler sağlamamıza olanak tanır.',
            thirdParty1: "Üçüncü Taraf Çerezleri: Ayrıca, Google Analytics gibi üçüncü taraf çerezlerini kullanabiliriz, web sitemizin performansını analiz etmek ve optimize etmek için. Bu çerezler, ilgili üçüncü tarafların gizlilik politikalarına tabidir. Çerezleri Yönetme: Çerezleri istediğiniz gibi kontrol edebilir ve/veya silebilirsiniz. Çoğu web tarayıcısı çerezleri otomatik olarak kabul eder, ancak genellikle tercih ederseniz tarayıcı ayarlarınızı çerezleri reddedecek şekilde değiştirebilirsiniz. Bu, web sitesinin tüm özelliklerinden tam olarak yararlanmanızı engelleyebilir.",
            thirdParty2: "Bu Çerez Politikası'nı zaman zaman güncelleme hakkını saklı tutarız. Herhangi bir değişiklik için bu politikayı periyodik olarak gözden geçirmenizi öneririz. Herhangi bir değişiklikten sonra web sitemizi kullanmaya devam etmeniz, güncellenmiş politikayı kabul ettiğiniz anlamına gelir.",
            thirdParty3: "Bize Ulaşın: Çerez Politikamız hakkında herhangi bir sorunuz varsa, lütfen bizimle [contact@primusotel.com] adresinden iletişime geçin.",

            // -------- Privacy Policy -------- //
            // -------- Gizlilik Politikası -------- //
            privacyPolicyPrimus: "Primus Otel İçin Gizlilik Politikası",
            privacyPolicyP1: "Bu Gizlilik Politikası, Primus Otel'in [www.primusotel.com] web sitesinin ('Site') kullanıcılarından (her biri bir 'Kullanıcı') toplanan bilgileri nasıl topladığını, kullandığını, muhafaza ettiğini ve ifşa ettiğini özetlemektedir. Bu politika Site ve Primus Otel'in sunduğu tüm ürün ve hizmetler için geçerlidir.",
            privacyPolicyP2: 'Kişisel Kimlik Bilgileri: Kullanıcıların sitemizi ziyaret etmesi, siteye kaydolması, sipariş vermesi, form doldurması, ankete yanıt vermesi ve bunlarla sınırlı olmamak üzere çeşitli yollarla Kullanıcılardan kişisel kimlik bilgilerini toplayabiliriz. Sitemizde kullanıma sunduğumuz diğer faaliyetler, hizmetler, özellikler veya kaynaklarla bağlantılı olarak. Kullanıcılardan uygun olduğu şekilde ad, e-posta adresi, posta adresi, telefon numarası bilgileri istenebilir.',
            privacyPolicyP3: 'Kişisel Olmayan Kimlik Bilgileri: Sitemizle etkileşimde bulunduklarında Kullanıcılar hakkında kişisel olmayan kimlik bilgileri toplayabiliriz. Kişisel olmayan kimlik bilgileri, tarayıcı adını, bilgisayar türünü ve Kullanıcıların Sitemize bağlantı araçlarına (işletim sistemi ve kullanılan İnternet servis sağlayıcıları gibi) ilişkin teknik bilgileri içerebilir.',
            privacyPolicyP4: 'Web Tarayıcı Çerezleri: Sitemiz, Kullanıcı deneyimini geliştirmek için "tanımlama bilgileri" kullanabilir. Kullanıcıların web tarayıcıları, kayıt tutma amacıyla ve bazen de onlar hakkındaki bilgileri takip etmek amacıyla çerezleri sabit disklerine yerleştirir. Kullanıcılar, web tarayıcılarını çerezleri reddedecek veya çerezler gönderildiğinde kendilerini uyaracak şekilde ayarlamayı seçebilirler. Bunu yapmaları halinde Sitenin bazı bölümlerinin düzgün çalışmayabileceğini unutmayın.',
            privacyPolicyP5: 'Toplanan Bilgileri Nasıl Kullanıyoruz: Primus Otel, Kullanıcıların kişisel bilgilerini aşağıdaki amaçlarla toplayabilir ve kullanabilir:',
            privacyPolicyLi1: 'Müşteri hizmetlerini geliştirmek için.',
            privacyPolicyLi2: 'Kullanıcı deneyimini kişiselleştirmek için.',
            privacyPolicyLi3: 'Ödemeleri işleme koymak için.',
            privacyPolicyLi4: 'Periyodik e-postalar göndermek için.',
            privacyPolicyP6: 'Bilgilerinizi Nasıl Koruyoruz: Kişisel bilgilerinizi, kullanıcı adınızı, şifrenizi, işlem bilgilerinizi ve saklanan verileri yetkisiz erişime, değiştirmeye, ifşa etmeye veya imha etmeye karşı korumak için uygun veri toplama, depolama ve işleme uygulamalarını ve güvenlik önlemlerini benimsiyoruz. Sitemizde.',
            privacyPolicyP7: 'Kişisel Bilgilerinizin Paylaşılması: Kullanıcıların kişisel kimlik bilgilerini başkalarına satmıyoruz, takas etmiyoruz veya kiralamıyoruz. Ziyaretçilere ve kullanıcılara ilişkin herhangi bir kişisel kimlik bilgisiyle bağlantılı olmayan genel toplu demografik bilgileri, yukarıda belirtilen amaçlar doğrultusunda iş ortaklarımız, güvenilir bağlı kuruluşlarımız ve reklamverenlerimiz ile paylaşabiliriz.',
            privacyPolicyP8: 'Bu Gizlilik Politikasında Yapılacak Değişiklikler: Primus Otel, bu gizlilik politikasını dilediği zaman güncelleme takdirine sahiptir. Topladığımız kişisel bilgilerin korunmasına nasıl yardımcı olduğumuz konusunda bilgi sahibi olmaları için Kullanıcıların herhangi bir değişiklik olup olmadığını görmek için bu sayfayı sık sık kontrol etmelerini öneririz. Bu gizlilik politikasını periyodik olarak gözden geçirmenin ve değişikliklerden haberdar olmanın sizin sorumluluğunuz olduğunu kabul ve beyan edersiniz.',
            privacyPolicyP9: 'Bu Şartları Kabulünüz: Bu Siteyi kullanarak, bu politikayı kabul ettiğinizi belirtirsiniz.',

            // -------- Özel Etkinlikler -------- //
            welcomeExclusive: "Ayrıcalıklı Buluşmalara Hoş Geldiniz",
            primusInvite: "Primus olarak sizleri, butik otelimizin lüks atmosferinde etkinliklerinize ev sahipliği yaparak, özel anlarınızı unutulmaz deneyimlere dönüştürmeye davet ediyoruz. İster kurumsal bir toplantı, ister samimi bir buluşma olsun, özel ekibimiz kusursuz ve unutulmaz bir etkinlik için her ayrıntının titizlikle hazırlanmasını sağlamak için burada.",
            memories: "Kusursuz Hizmet, Unutulmaz Anılar",
            beyond: "Primus'ta taahhüdümüz şık mekanlar ve nefis yemekler sunmanın ötesine geçiyor. Ekibimiz, etkinliğinizin sorunsuz bir şekilde gerçekleşmesini sağlayarak kusursuz hizmet sunmaya kendini adamıştır. Titiz planlamadan uygulamaya kadar her ayrıntıyla ilgilenirken, kalıcı anılar yaratmaya odaklanabileceğiniz bir atmosfer yaratmaya çalışıyoruz.",
            yourVision: "Sizin Vizyonunuz, Bizim Uzmanlığımız",
            collaborate: "Etkinlik uzmanlarımızla işbirliği yaparak vizyonunuzu hayata geçirebilirsiniz. Aklınızda belirli bir tema olsun ya da etkinliğinizin temasının oluşturulmasında yardıma ihtiyacınız olsun; Primus fikirlerinizi gerçeğe dönüştürmek için burada! Bizim uzmanlığımız, sizin kişisel tercihlerinizle birleştiğinde, Primus'taki özel etkinliğinizin gerçek anlamda tarzınızı ve amacınızı yansıtacağından emin olabilirsiniz.",

            // -------- Restoran -------- //
            savor: "Primus'un Gerçek Doğasının Tadını Çıkarın",
            worldOfExquisite: "Alaçatı'nın büyüleyici fonunda zarif bir dünyası olan Primus, sizi hayatın küçük zevklerinin tadını çıkarmaya davet ediyor. Nefis yemekler, birinci sınıf bir hizmet anlayışı bir araya gelerek Primus'taki konaklamanızın her dakikasını muhteşem bir ziyafet şölenine dönüştürüyor.",
            restaurantHours: "Restoran Saatleri:",
            primusRestaurant: "Primus Restaurant",
            culinary: "Primus Restaurant'ta Mutfak Senfonisi",
            experience: "Ege ve dünya mutfaklarının zengin dokusunun sergilendiği Primus Restaurant'ta lezzetlerin uyumlu karışımını deneyimleyin. Yerel kaynaklı taze malzemeler kullanılarak titizlikle hazırlanan her yemeğin bir başyapıt olduğu bir atmosfere kendinizi kaptırın. Yöresel tatların ön planda olduğu sabah kahvaltısından, lezzetli akşam yemeklerine kadar Primus Restaurant, farklı damak tatlarına hitap eden zengin bir menü sunuyor. Gelin; her lokmanın mutfak sanatının bir kutlaması ve olağanüstü yemeklere olan bağlılığımızın bir yansıması olduğu gastronomik bu yolculuğun tadını çıkarın.",
            savorRefresh: "Havuz Başında",
            momentsPoolside: "Serinletici Anların Tadını Çıkarın",
            lounge: "Primus'ta boş zamanlarınızda dinlenin ve havuz kenarında en sevdiğiniz içeceklerin tadını çıkarın. Sakinleştirici ambiyansın ve yemyeşil doğanın, deneyiminizi bir üst seviyeye taşımasına, ve keyifli bir ortam yaratmasına izin verin. İster leziz, ister canlandırıcı bir kokteyl olsun, Primus Bar, havuz başı ortamınızın her yudumunu saf bir mutluluk anına dönüştürmeye hazır. Primus'taki sakin anların ve şık bir şekilde dinlenmenin keyfinin şerefine!",
            raiseToast: "Primus Bar'da Sofistike Bir Kutlama",
            escapeToRealm: "Her yudumun bir zanaatkarlık ve zarafet öyküsü anlattığı Primus Bar'da rafine bir tat yolculuğuna kaçış… Butik otelimizin içinde yer alan bar, miksoloji sanatını sevenler için bir cennet: Kendinizi modern şıklığı bir tutam sihirle kusursuz bir şekilde birleştiren bu ambiyansa bırakın. Uzman miksologlarımız olağanüstü bir kokteyl seçkisi hazırlayarak her içeceğin başlı başına bir kutlama olmasını sağlıyor. İster keşifle geçen bir günün ardından rahatlıyor olun, ister hareketli bir gece geçirmek istiyor olun, Primus Bar sizi her yudumda sıra dışılığın tadını çıkarmaya davet ediyor.",

            // -------- Oda -------- //
            roomsGuide: "Oda, Suit ve Bungalovlar",
            roomDescription: "Primus'taki oda ve süitlerimiz, bölgenin büyüleyici doğasıyla birleşen bir lüksü temsil ediyor. Yerden tavana uzanan geniş pencerelere sahip odalarımız, etrafınızda yer alan güzellikleri sergileyen, nefes kesici manzaralar sunmaktadır. Konfor ve tasarımı kusursuz bir şekilde birleştiren odalarımızda konaklarken, her anında sofistike bir konaklama deneyimi yaşayacaksınız.",
            jacuzzi: "Bungalow Jakuzi",
            bespoke: "Özel Bahçeler ve Kişisel Bir Jakuzi ile Kişisel Mutluluğun Tadını Çıkarın",
            redefine: "Primus'ta, benzersiz deneyiminizin merkezindeki Jakuzi Bungalovlarımızla lüksün anlamını yeniden tanımlıyoruz. Her bungalov, kendi özel jakuzisi ve büyüleyici bahçesi olan bir huzur ve sakinlik sığınağıdır.",
            bungalov1: "1. Bungalow Jakuzi Bahçesiz: Açıklama: Özel bir jakuzi ve belirli bir manzaraya sahip olmayan sıcak bir bungalov. Boyut: 20 m²",
            bungalov2: "2. Bungalow Jakuzi Bahçeli: Açıklama: Özel bir jakuziye ve büyüleyici özel bir bahçeye sahip davetkar bir bungalov. Boyut: 20 m²",
            bungalov3: "1. Bungalow Jakuzi Bahçesiz: Açıklama: Özel bir jakuzi ve belirli bir manzaraya sahip olmayan sıcak bir bungalov. Boyut: 20 m²",
            bungalov4: "2. Bungalow Jakuzi Bahçeli: Açıklama: Özel bir jakuziye ve büyüleyici özel bir bahçeye sahip davetkar bir bungalov. Boyut: 20 m²",

            // -------- Sürdürülebilirlik-------- //
            primusSustainability: "Primus'ta Sürdürülebilirlik: Daha Yeşil Bir Yarın",
            sustainabilityDescription: "Primus'ta sürdürülebilirlik, vizyonumuzun yapı taşlarından birini temsil ediyor. Geri dönüştürülmüş hediyelik eşya ambalajlarından 'Çarşaflarımı Değiştir' kart sistemimiz gibi yenilikçi uygulamalara kadar, bizimle yaşayacağınız konaklama deneyiminin her anı, çevresel etkiyi en aza indirme kararlılığımızı yansıtır. Lüksün sorumlulukla buluştuğu, daha yeşil bir yarını kucaklamak için bize katılın.",
            greenOasis: "Primus Otel'in Yeşil Cennetine Hoş Geldiniz:",
            stepInto: "Sürdürülebilir cennetimize adım attığınızda, etrafınızı çevreleyen yeşillikler, size, doğaya olan bağlılığımızın hikayelerini fısıldar. Canlı bitkilerle peyzajlanmış girişimiz, yeşil bir sığınak yaratmaya olan bağlılığımızı simgeliyor. Her yaprağın bir sürdürülebilirlik hikayesi anlattığı bu yaşayan alanda bize katılın ve doğal dünyanın güzelliğiyle uyum içinde bir konaklama deneyimi yaşayın.",
            amenities: "Sürdürülebilir Olanaklar: Günlük Rutine Yeşil Bir Yaklaşım",
            discoverGreener: "Daha Yeşil Bir Dokunuşu Keşfedin: Primus Otel'de, her detayda sürdürülebilirliği önceliğimiz haline getiriyoruz. Sabunlarımız, şampuanlarımız ve saç kremlerimiz, lüksten ödün vermeden çevresel etkiyi azaltmaya yönelik geri dönüştürülmüş ambalajlarda sunulmaktadır. Küçük seçimler büyük fark yaratabileceğine inanıyor ve Primus'ta size sadece mükemmel değil, aynı zamanda çevreye duyarlı bir konaklama sunmayı amaçlıyoruz.",
            ecoStay: "Çevreye Duyarlı Konaklama: Primus Otel'in Sürdürülebilirlik Taahhüdü",
            elevateStay: "Konaklamanızı Bir Üst Seviyeye Taşıyın, Gezegenimizi Koruyun: Primus Otel, kartlı sistemimizde de görüleceği üzere sürdürülebilirliğe adanmıştır. 'Çarşaflarımı Değiştir' kartını yatağınızın üzerine bıraktığınızda, gereksiz yıkamaya gerek kalmadan, kimyasal kullanımımızı en aza indirmemize yardımcı oluyorsunuz ve biz de sayenizde daha çevre dostu bir ortam sağlıyoruz. Her seferinde tek bir düşünceli seçimle, daha yeşil bir yarın taahhüdümüze bize katılın.",
            exploreOur: "Sürdürülebilirliğe Taahhüdümüzü Keşfedin:",
            scanQR: "Resmi Sürdürülebilirlik Sertifikamıza erişmek için QR kodunu tarayın. Daha yeşil, daha sürdürülebilir bir geleceğe doğru çabalarken Primus'un çevre dostu girişimlerinin ayrıntılarına göz atın. Sorumlu konukseverliğe yolculuğunuz burada başlıyor.",

            // -------- Kullanım Şartları -------- //
            termsOfUse: "Primus Otel Kullanım Şartları Anlaşması",
            welcomePrimusWebsite: "Primus Otel web sitesine hoş geldiniz. Bu web sitesine erişerek, aşağıdaki kullanım şartlarına uyacağınızı ve bağlı olacağınızı kabul edersiniz. Bu şartların herhangi bir bölümüyle anlaşmıyorsanız, lütfen web sitemizi kullanmayın.",
            useOfWebsite: "Web Sitesinin Kullanımı: Bu web sitesinin içeriği yalnızca genel bilgi ve kullanımınız içindir. Önceden bildirim yapılmaksızın içerik değiştirilebilir.",
            responsibilities: "Kullanıcının Sorumlulukları: Bu web sitesindeki herhangi bir bilgi veya materyali kullanımınızın riski tamamen size aittir ve biz sorumlu olmayacağız. Bu web sitesi aracılığıyla sunulan herhangi bir ürün, hizmet veya bilginin aşağıdaki standartlara uygun olmasını sağlamak sizin sorumluluğunuzdur. özel gereksinimleriniz.",
            intellectualProperty: "Fikri Mülkiyet Hakları: Bu web sitesinin tüm fikri mülkiyet hakları bizim tarafımızdan saklıdır. İzinsiz kullanımı yasaktır.",
            thirdParty: "Üçüncü Taraf Bağlantıları: Zaman zaman bu web sitesi başka web sitelerine bağlantılar da içerebilir. Bu bağlantılar daha fazla bilgi sağlamanız için size kolaylık sağlamak amacıyla sağlanmıştır. Web sitelerini onayladığımız anlamına gelmezler. Bağlantı verilen web sitelerinin içeriğine ilişkin hiçbir sorumluluğumuz yoktur.",
            privacy: "Gizlilik: Bu web sitesini kullanımınız Gizlilik Politikamıza tabidir. Lütfen web sitesini de düzenleyen ve kullanıcıları veri toplama uygulamalarımız hakkında bilgilendiren Gizlilik Politikamızı inceleyin.",
            disclaimer: "Yasal Uyarı: Bu web sitesinin sayfalarının içeriği yalnızca genel bilginiz ve kullanımınız içindir. Haber verilmeden değiştirilebilir. Ne biz ne de herhangi bir üçüncü taraf, bu web sitesinde bulunan veya sunulan bilgi ve materyallerin belirli bir amaç için doğruluğu, güncelliği, performansı, eksiksizliği veya uygunluğu konusunda herhangi bir garanti vermiyoruz.",
            changesTermsOf: "Kullanım Koşullarında Değişiklikler: Primus Otel, bu sayfayı güncelleyerek bu kullanım koşullarını dilediği zaman değiştirme hakkını saklı tutar. Herhangi bir değişiklikten haberdar olduğunuzdan emin olmak için bu sayfayı düzenli aralıklarla kontrol etmelisiniz.",
            changesTermsOfLast: "Bu web sitesini kullanarak, söz konusu kullanımın riskinin tamamen size ait olduğunu ve bu Kullanım Koşulları Sözleşmesinde belirtilen hüküm ve koşulları kabul ettiğinizi kabul edersiniz. Herhangi bir sorunuz veya endişeniz varsa lütfen [contact@primusotel.com] adresinden bizimle iletişime geçin.",

            // -------- Hakkımızda -------- //
            aboutPrimus: "Primus Hakkında",
            boutiqueParadise: "Primus Otel, Ege'nin sıcak rüzgarlarının esintisiyle çevrili, butik bir cennettir. Alaçatı'nın zarif sakinliği ve geleneksel mimarisiyle çevrili olarak, Primus Otel, konuklarını huzurlu bir kaçışa davet eder. Misafirperverlikte yeni standartlar belirleyen, lüks ve zarafeti benzersiz bir deneyimle birleştiren Primus Otel, unutulmaz bir konaklama sunar.",
            ourMission: "Misyonumuz",
            missionStatement: "Primus, konuklarını şehrin kalabalığından uzaklaştıran benzersiz ve sıcak bir kaçış sunar. Misafirlerimizin rahatlığı ve keyfi bizim önceliğimizdir; bu nedenle, her ayrıntıyı özenle düşünüyor ve konaklamalarını unutulmaz kılmak için çaba sarf ediyoruz.",
            communityInvolvement: "Topluma Katılım",
            communityEfforts: "Primus, çevresindeki topluluğa katkıda bulunmaktan gurur duyar. Sürdürülebilirlik çabalarından yerel yardım projelerine kadar, Primus Otel, etkileyici bir fark yaratmak için topluma adanmıştır.",

            // -------- HomeCarousel -------- //
            instagramText_1: "Bizi Anlık Takip Edin: Etkinliklerden, fırsatlardan ve son gelişmelerden haberdar olun! ",
            instagramText_2: "@primusotel",

            // -------- Room.js -------- //
            roomCarouselRoom1Subtitle: "Bungalow Jakuzi",
            roomCarouselRoom2Subtitle: "Deluxe Dublex",
            roomCarouselRoom3Subtitle: "Deluxe Suite",
            roomCarouselRoom4Subtitle: "Ekonomik Odalar",
            roomCarouselRoom5Subtitle: "Standart Havuz Manzaralı Odalar",
            roomCarouselRoom6Subtitle: "Balkonlu Havuz Manzaralı Oda",
            roomCarouselRoom1Description: "Tamamen size ait bir jakuzi ve büyüleyici bir bahçe ile donatılmış bungalowumuzda gösterişten uzak bir lüksün tadını çıkarın. Konforun huzurla buluştuğu, size özel bir rahatlama vahası yaratan gözlerden uzak bir dinlenme deneyimini yaşayın.",
            roomCarouselRoom2Description: "İki katlı sofistike bir cennet olan deluxe dubleks odalarımızda, konaklama deneyiminizi bir üst seviyeye taşıyın. Modern şıklığı en yalın haliyle yansıtan sofistike iki katlı bir cennet… Konforlu bir tatil deneyimi sunan bu odaların geniş tasarımının ve özenle düşünülmüş olanaklarının keyfini çıkarın.",
            roomCarouselRoom3Description: "Jakuzili Suitimizde stilin tadını çıkarın; lüksle şımartılmanın keyfini sürün. Özel jakuzinizde rahatlatıcı bir banyoya dalın ve süitin zarif ortamının konaklama deneyiminizi sonuna kadar zenginleştirmesine izin verin.",
            roomCarouselRoom4Description: "Ekonomik standart odalarımızda, ekonomik bir konaklamanın keyfini çıkarın. Çevre dostu özelliklerle titizlikle hazırlanan bu oda, çevresel sorumluluk ve kalitenin konaklamanın önceliğinde olduğunu sağlar.",
            roomCarouselRoom5Description: "Standart Havuz Manzaralı Odalar'da pitoresk manzaraların tadını çıkarın. Doğal güzellikleriyle çevrili olmanın huzuruna dalarken, iyi donanımlı standart konaklamanın konforunu ve kolaylığını yaşayın.",
            roomCarouselRoom6Description: "Balkonlu Havuz Manzaralı Odada şıklığı deneyimleyin. Bu özenle tasarlanmış alan, şirin bir balkon, bir Fransız yatağı ve tek kişilik yatak içerir, her detayın kalışınızı artırdığı zarif ve konforlu bir kaçış sunar.",
            exploreText: "Keşfet",
            room1CardDescription1: "Kendinize Özgü Lüksün Tadını Çıkarın: Her Birinde Büyüleyici Bahçe ve Özel Jakuzi Bulunan Özel Bungalovlar",
            room1CardDescription2: "Primus'ta, özel jakuzi bungalovlarımızla kendini şımartmanın anlamını yeniden tanımlıyoruz. Burada sizin eşsiz deneyiminiz merkezde yer alıyor. Her bungalow, kendi özel jakuzisi ve büyüleyici bahçesi ile huzurun diğer bir adı!",
            room1CardDescription3: "1. Bungalow Jakuzi Bahçesiz: Özel bir jakuziye ve 20 m²’lik konforlu alanlara sahip bungalov.",
            room1CardDescription4: "2. Bungalow Jakuzi Bahçeli: Özel bir jakuziye ve keyfini sürebileceğiniz özel bir bahçeye sahip 20 m²’lik davetkar bir bungalov.",

            // -------- RoomCard.js-------- //
            room1CardTitle: "Deluxe Dublex",
            room1CardParagraph_one: "Deluxe dublex ile konaklamanızı bir üst seviyeye yükseltin; modern çizgilerle zarif detayları buluşturan, iki katlı bir cennet. Geniş alanların ve ince düşünülmüş olanakların tadını çıkararak şık ve konforlu bir tatilin keyfini sürün.",
            room1CardParagraph_two: "Üst katında Fransız çift kişilik yatak, alt katında katlanabilir kanepe (2 kişilik yatağa dönüşen), 2 banyo, 2 TV ve 2 klima bulunan, iki katlı geniş süit.",
            room2CardTitle: "Deluxe Suite",
            room2CardParagraph_one: "Deluxe Suite ile stilin tadını çıkarın, lüksün konforlu buluştuğu zarif bir süit. Özel küvetinizde rahatlatıcı bir banyoya adım atın ve süitin zarif ambiyansıyla konaklamanızı en üst düzeye çıkarın.",
            room2CardParagraph_two: "Zemin katta yer alan, Fransız balkonlu, küvetli ve havuz manzaralı 30 m²’lik süit.",
            room3CardTitle: "Ekonomik Odalar",
            room3CardParagraph_one: "Eko standart odalarımızda bütçe dostu bir konaklama deneyimi yaşayın. Çevreye duyarlı özelliklerle tasarlanan bu oda, konforlu ve sürdürülebilir bir tatil deneyimi sunarak, hem çevresel sorumluluğun hem de kalitenin konaklamanızda ön planda olmasını sağlar.",
            room3CardParagraph_two: "Twin yataklı veya çift kişilik yataklı 15 m² alana sahip konforlu oda.",
            room4CardTitle: "Standart Havuz Manzaralı Odalar",
            room4CardParagraph_one: "İyi donanımlı standart odalarımızın konfor ve rahatlığının keyfini çıkarırken, kendinizi çevrenin huzuruna bırakın.",
            room4CardParagraph_two: "1. Bahçe Katı Havuz Manzaralı Oda: -1. Katta yer alan, 20 m² yaşam alanına sahip, çift kişilik Fransız yatağına sahip odalarımız.",
            room4CardParagraph_three: "2. Fransız Balkon Havuz Manzaralı Oda: Zemin katta yer alan, 20 m² yaşam alanına sahip, Fransız yataklı odalarımız.",
            room5CardTitle: "Balkonlu Havuz Manzaralı Oda",
            room5CardParagraph_one: "Balkonlu ve havuz manzaralı odamızda sofistike bir deneyim yaşayın. Otelin 1. Katında yer alan ve 22 m2’lik yaşam alanına sahip bu özenle tasarlanmış odada, keyifli bir balkon, çift kişilik bir fransız yatak ve bir merdivenle çıkabileceğiniz çatı katında konumlanan tek kişilik yatak bulunuyor. Balkonlu ve havuz manzaralı bu şirin odada, her ayrıntının konaklamanızı zenginleştirdiği zarafetin ve konforun tadını çıkarın.",
            room5CardParagraph_two: "",

            // -------- ContactForm.js-------- //
            contanctFormTitle: "Bize Mesaj Gönderin",
            contanctFormName: "İsim",
            contanctFormMail: "E-Mail",
            contanctFormMessage: "Mesaj",
            contanctFormSend: "Gönder",
            responsibilities: "Kullanıcının Sorumlulukları: Bu web sitesindeki herhangi bir bilgi veya materyali kullanımınızın riski tamamen size aittir ve biz sorumlu olmayacağız. Bu web sitesi aracılığıyla sunulan herhangi bir ürün, hizmet veya bilginin aşağıdaki standartlara uygun olmasını sağlamak sizin sorumluluğunuzdur.",
            byUsing: "Bu web sitesini kullanarak, söz konusu kullanımın riskinin tamamen size ait olduğunu ve bu Kullanım Koşulları Sözleşmesinde belirtilen hüküm ve koşulları kabul etmiş olursunuz. Herhangi bir sorunuz veya endişeniz varsa, lütfen [contact@primusotel adresinden bizimle iletişime geçin. .com].",

            // -------- İletişim -------- //
            contactDetails: "İletişim Bilgileri",
            email: "E-posta: info@primusotel.com",
            contactPhoneNumber: "Telefon Numarası",
            contactEmail: "Email Adresi",
            address1: "Adres",
            comingSoonContact: "Yakında",

            // -------- Footer -------- //
            rightsReserved: "Tüm Hakları Saklıdır",
            explore: "KEŞFET",
            rightsDetails: "Primus Otel © 2024",
            getInspired: "İlham Al",
            receiveFooter: "Kişiselleştirilmiş etkinlikler ve heyecan verici destinasyonlar için doğrudan bizimle bağlantı kurun. Primus ile unutulmaz anlar yaratmaya başlamak için hemen arayın.",
            links: "BAĞLANTILAR",
            cookiePolicy: "Çerez Politikası",
            copyright: "Tüm Hakları Saklıdır 2024, Codelps",
        }
    },
}

const languages = ['en', 'tr'];

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: languages,
        debug: false,
        whitelist: languages,
        lng: localStorage.getItem('language') || 'en',
        interpolation: {
            escapeValue: false
        },
        resources: resources
    })

export default i18next


