import React, { useEffect, useRef, useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import leftButton from '../assets/svg/06-icon-button-left.svg';
import rightButton from '../assets/svg/07-icon-button-right.svg';
import { useSpring, animated } from 'react-spring'

import room0101 from '../assets/images/room/01-01-room.webp';
import room0102 from '../assets/images/room/01-02-room.webp';
import room0103 from '../assets/images/room/01-03-room.webp';
import room0104 from '../assets/images/room/01-04-room.webp';
import room0105 from '../assets/images/room/01-05-room.webp';
import room0106 from '../assets/images/room/01-06-room.webp';
import room0201 from '../assets/images/room/02-01-room.webp';
import room0301 from '../assets/images/room/03-01-room-p.webp';
import room0302 from '../assets/images/room/03-02-room-p.webp';
import room0303 from '../assets/images/room/03-03-room-p.webp';
import room0304 from '../assets/images/room/03-04-room-p.webp';
import room0305 from '../assets/images/room/03-05-room-p.webp';
import room0306 from '../assets/images/room/03-06-room-p.webp';
import room0307 from '../assets/images/room/03-07-room-p.webp';
import room0308 from '../assets/images/room/03-08-room-p.webp';
import room0309 from '../assets/images/room/03-09-room-p.webp';
import room0310 from '../assets/images/room/03-10-room-p.webp';

import RoomCard from '../components/RoomCard';
import i18next from '../i18n'
import { useLocation } from 'react-router-dom';

const Room = () => {

  const [props, api] = useSpring(() => ({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 500 },
    onRest: () => api.start({ opacity: 1 }), // Animasyon bitiminde yeni animasyonu başlat
  }));

  const elementRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const { t } = i18next;
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const scrollToElement = urlParams.get('scrollTo');

    if (scrollToElement) {
      const element = document.getElementById(scrollToElement);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location.pathname, location.search]);

  const scrollToElement = (index) => {
    console.log(index)
    let adjustedIndex = index;
    if (firstImageIndex > 0) {
      adjustedIndex += firstImageIndex;
    }
    console.log(adjustedIndex)
    const ref = elementRefs[adjustedIndex];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const [firstImageIndex, setFirstImageIndex] = useState(0);
  const [lastImageIndex, setLastImageIndex] = useState(2);
  const [visibleImageCount, setVisibleImageCount] = useState(3);

  const updateImageIndexes = () => {
    const screenWidth = window.innerWidth;
    let newLastIndex;
    if (screenWidth < 900) {
      newLastIndex = 1;
    } else if (screenWidth < 1200) {
      newLastIndex = 2;
    } else {
      newLastIndex = 3;
    }
    setVisibleImageCount(newLastIndex);
    setFirstImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex : 0));
    setLastImageIndex((prevIndex) => (prevIndex < data.length - newLastIndex ? prevIndex + newLastIndex : data.length - 1));
  };

  const handleLeftArrow = () => {
    api.start({ opacity: 0.7 });
    setFirstImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    setLastImageIndex((prevIndex) => (prevIndex > visibleImageCount ? prevIndex - 1 : visibleImageCount - 1));
  };

  const handleRightArrow = () => {
    api.start({ opacity: 0.7 });
    setFirstImageIndex((prevIndex) => (prevIndex < data.length - visibleImageCount ? prevIndex + 1 : data.length - visibleImageCount));
    setLastImageIndex((prevIndex) => (prevIndex < data.length - 1 ? prevIndex + 1 : data.length - 1));
  };

  useEffect(() => {
    updateImageIndexes();
    window.addEventListener('resize', updateImageIndexes);
    return () => window.removeEventListener('resize', updateImageIndexes);
    // eslint-disable-next-line
  }, []);

  const [backgroundStyle, setBackgroundStyle] = useState();

  useEffect(() => {
    const calculateImageHeight = () => {
      const imageElement = document.getElementById('button-calculate');
      const wrapper = document.getElementById('image-wrapper');

      if (imageElement && wrapper) {
        if (imageElement.complete) {
          const imageRect = imageElement.getBoundingClientRect();
          const imageHeight = imageRect.bottom;
          const startY = 0;
          const endY = imageHeight;
          setBackgroundStyle({
            background: `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`,
          });
        } else {
          imageElement.addEventListener('load', () => {
            const imageRect = imageElement.getBoundingClientRect();
            const imageHeight = imageRect.bottom;
            const startY = 0;
            const endY = imageHeight;
            setBackgroundStyle({
              background: `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`,
            });
          });
        }
      }
    };

    const handleResize = () => {
      calculateImageHeight();
    };

    calculateImageHeight();
    window.addEventListener('resize', handleResize);

    const imageElement = document.getElementById('button-calculate');
    if (imageElement.complete) {
      calculateImageHeight();
    } else {
      imageElement.addEventListener('load', calculateImageHeight);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      imageElement.removeEventListener('load', calculateImageHeight);
    };
  }, []);

  const data = [
    {
      image: room0101,
      subtitle: t("roomCarouselRoom1Subtitle"),
      description: t("roomCarouselRoom1Description"),
      exploreText: t("exploreText"),
    },
    {
      image: room0102,
      subtitle: t("roomCarouselRoom2Subtitle"),
      description: t("roomCarouselRoom2Description"),
      exploreText: t("exploreText"),
    },
    {
      image: room0103,
      subtitle: t("roomCarouselRoom3Subtitle"),
      description: t("roomCarouselRoom3Description"),
      exploreText: t("exploreText"),
    },
    {
      image: room0104,
      subtitle: t("roomCarouselRoom4Subtitle"),
      description: t("roomCarouselRoom4Description"),
      exploreText: t("exploreText"),
    },
    {
      image: room0105,
      subtitle: t("roomCarouselRoom5Subtitle"),
      description: t("roomCarouselRoom5Description"),
      exploreText: t("exploreText"),
    },
    {
      image: room0106,
      subtitle: t("roomCarouselRoom6Subtitle"),
      description: t("roomCarouselRoom6Description"),
      exploreText: t("exploreText"),
    },
  ];

  const room1Allimages = [room0201, room0301, room0302, room0303, room0304, room0305, room0306, room0307, room0308, room0309, room0310];
  const room1images = [...room1Allimages.slice(1)];

  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  const openSlider = (index) => {
    setSliderSettings({ ...sliderSettings, initialSlide: index });
    setIsSliderOpen(true);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };

  return (
    <div className='text-[#303030] flex flex-col items-center'>
      {/* 1. Container */}
      <div id="image-wrapper" style={backgroundStyle} className='pt-[120px] '>
        <div className='flex justify-center items-center text-center tablet:flex-row phone:flex-col-reverse phone:mx-0'>
          <div className='phone:w-[90%] tablet:w-[80%] desktop:w-[60%]'>
            <h2 className='text-[36px] tracking-1 font-light mb-4 leading-[45px]'>
              {t("roomsGuide")}
            </h2>
            <h3 className='phone:text-[18px] tablet:text-[22px] font-extralight leading-[18px] tracking-[1px]'>
              {t("roomDescription")}
            </h3>
          </div>
        </div>
        <div className="relative cursor-pointer">
          <div className="flex justify-center overflow-x-auto overflow-y-hidden phone:gap-4 tablet:gap-6 desktop:gap-10 mt-6">
            <button className='flex justify-center items-center mb-[270px]' onClick={handleLeftArrow}>
              <img id='button-calculate' className="phone:w-2/3" src={leftButton} alt="Left Button" />
            </button>
            {data.slice(firstImageIndex, lastImageIndex + 1).map((item, index) => (
              <animated.div
                style={{ ...props }}
                key={`item-${index}`}
                className={`flex flex-col items-center phone:w-[200px] tablet:w-[350px]`}
                onClick={() => scrollToElement(index)}
              >
                <img className='object-cover' src={item.image} alt='view of hotel' />
                <div className='pb-8 pl-1 phone:text-center tablet:text-left flex flex-col phone:items-center tablet:items-start  h-[300px]'>
                  <h2 className="text-[#303030] mt-6 mb-4 text-[22px] font-normal leading-6 tracking-[0.98px] font-spartan">
                    {item.subtitle}
                  </h2>
                  <h2 className="text-[#303030] phone:text-[14px] tablet:text-[16px] font-normal leading-[16px] tracking-[0.8px]">
                    {item.description}
                  </h2>
                  <h4 className='w-max border-b border-[#1C242E] cursor-pointer text-[13px] leading-[18.85px] font-normal text-[#1C242E] tracking-[0.8px] mt-[20px]'>
                    {item.exploreText}
                  </h4>
                </div>
              </animated.div>
            )).slice(0, visibleImageCount)}
            <button onClick={handleRightArrow} className='flex justify-center items-center mb-[270px]'>
              <img className="phone:w-2/3" src={rightButton} alt="Right Button" />
            </button>
          </div>
        </div>
      </div>

      {/* 2. Container */}
      <div ref={elementRefs[0]} className="h-auto w-screen bg-[#1C242E] flex justify-center items-center mt-2 pb-6">
        <div className='text-[#F7F7F7] tablet:p-8 phone:p-0 tablet:text-left phone:text-center overscreen:w-[106rem] '>
          <div className='flex tablet:flex-row tablet:justify-between items-center phone:justify-center phone:flex-col'>
            <div className='tablet:w-[40%] tablet:mr-6 phone:w-[100%] phone:m-0 phone:flex phone: justify-center'>
              <img
                className="aspect-square object-cover tablet:pt-6 pb-6 phone:pt-[30px] phone:px-[16px]"
                src={room0201}
                alt='view of hotel'
                id='imageroom-0'
                onClick={() => {
                  openSlider(0);
                }}
              />
            </div>
            <div className='phone:w-[90%] tablet:w-[60%] desktop:ml-20 desktop:pr-24 tablet:ml-0 tablet:pr-0'>
              <h2 className='text-[30px] desktop:text-[44px] tracking-1 font-extralight mb-4 leading-[45px] tracking-[3px]'>
                {t("roomCarouselRoom1Subtitle")}
              </h2>
              <h3 className='text-xl font-extralight mb-8 leading-[24px]'>
                {t("room1CardDescription1")}
              </h3>
              <h3 className='text-xl font-extralight mb-8 leading-[24px]'>
                {t("room1CardDescription2")}
              </h3>
              <div className='flex flex-col'>
                <p className='desktop:inline tablet:hidden phone:inline mb-4'>
                  {t("room1CardDescription3")}
                </p>
                <p className='desktop:inline tablet:hidden phone:inline'>
                  {t("room1CardDescription4")}
                </p>
              </div>
            </div>
          </div>
          <div className='desktop:hidden tablet:flex flex-col phone:hidden mr-24 mt-6'>
            <h3 className='text-xl font-extralight mb-8 leading-[24px]'>
              {t("room1CardDescription3")}
            </h3>
            <h3 className='text-xl font-extralight mb-8 leading-[24px]'>
              {t("room1CardDescription4")}
            </h3>
          </div>
        </div>
      </div>

      {/* 3. Container */}
      <div className='px-[24.5px] mt-[50px] flex flex-wrap overscreen:w-[106rem]'>
        {room1images.map((image, index) => (
          <div key={index} className='w-full phone:w-[33%] tablet:w-[20%] p-2'>
            <img
              className='w-full object-cover cursor-pointer'
              src={image}
              alt={`view of room ${index + 1}`}
              onClick={() => {
                openSlider(index + 1);
              }}
            />
          </div>
        ))}
      </div>

      {/* Resim Kaydırıcı */}
      {isSliderOpen && (
        <div
          className='fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center'
          onClick={closeSlider}
        >
          <div className='relative max-w-screen-xl w-[750px] mx-4' onClick={(e) => e.stopPropagation()}>
            <button
              className='absolute top-0 right-2 text-black text-[50px] bg-transparent border-none z-[500]'
              onClick={closeSlider}
              style={{ width: '30px', height: '30px' }}
            >
              &times;
            </button>
            <Slider {...sliderSettings}
              className='z-[200] flex mx-auto phone:w-[44%] tablet:w-[85%] desktop:w-full'
            >
              {room1Allimages.map((image, index) => (
                <div key={index}>
                  <img className='w-full h-auto z-[199]' src={image} alt={`view of room ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}

      {/* 4. Container */}
      <RoomCard elementRefs={elementRefs} />
    </div>
  );
}

export default Room;
