import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

import i18next from '../i18n'

const ContactForm = () => {
    const { t } = i18next;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [messageError, setMessageError] = useState("");

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const validateForm = () => {
        let isValid = true;

        if (name.trim() === "") {
            setNameError("Name is required");
            isValid = false;
        } else {
            setNameError("");
        }

        if (email.trim() === "") {
            setEmailError("Email is required");
            isValid = false;
        } else if (!isValidEmail(email)) {
            setEmailError("Please enter a valid email address");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (message.trim() === "") {
            setMessageError("Message is required");
            isValid = false;
        } else if (message.length > 500) {
            setMessageError("Message should be 500 characters or less");
            isValid = false;
        } else {
            setMessageError("");
        }

        return isValid;
    };

    const isValidEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        if (validateForm()) {
            const serviceId = process.env.REACT_APP_SERVICE_ID;
            const templateId = process.env.REACT_APP_TEMPLATE_ID;
            const publicKey = process.env.REACT_APP_PUBLIC_KEY;

            const templateParams = {
                from_name: name,
                from_email: email,
                to_name: 'Primus Otel',
                message: message
            };

            toast.info('Mail is sending to us...', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            emailjs.send(serviceId, templateId, templateParams, publicKey)
                .then((response) => {
                    toast.success('Email sent successfully!', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                    setName(""); setEmail(""); setMessage("");
                })
                .catch((error) => {
                    toast.error('Failed to send email. Please try again later, or call us.', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                });
        } else {
            toast.error('Please check your informations.', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        }
    };

    return (
        <div className="mt-[70px] bg-[#F4EEEB] text-[#1C242E] flex justify-center">
            <div className='tablet:w-[60%] phone:w-[80%] overscreen:w-[60rem]'>
                <div className='text-center pt-20'>
                    <h2 className="text-[40px] font-light mb-6 leading-[50px] tracking-[0.8px]">{t("contanctFormTitle")}
                    </h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-10">
                        <label htmlFor="name" className="block text-sm font-medium text-[#303030]">
                            {t("contanctFormName")}
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={handleNameChange}
                            className="border-b-2 border-opacity-50 border-[#1C242E] focus:outline-none focus:border-black w-full py-2 bg-[#F4EEEB]"
                        />
                        {nameError && <p className="text-red-500">{nameError}</p>}
                    </div>

                    <div className="mb-10">
                        <label htmlFor="email" className="block text-sm font-medium text-[#303030]">
                            {t("contanctFormMail")}
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            className="border-b-2 border-opacity-50 border-[#1C242E] focus:outline-none focus:border-black w-full py-2 bg-[#F4EEEB]"
                        />
                        {emailError && <p className="text-red-500">{emailError}</p>}
                    </div>

                    <div className="mb-10">
                        <label htmlFor="message" className="block text-sm font-medium text-[#303030] pb-8">
                            {t("contanctFormMessage")}
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={message}
                            onChange={handleMessageChange}
                            className="border-2 border-opacity-50 border-[#1C242E] focus:outline-none focus:border-black w-full py-2 px-3 resize-none bg-[#F4EEEB]"
                        ></textarea>
                        {messageError && <p className="text-red-500">{messageError}</p>}
                    </div>

                    <button
                        type="submit"
                        className="mx-auto block text-[26px] text-[#303030] font-light hover:scale-125 hover:text-[#030303] transition-transform mb-8">
                        {t("contanctFormSend")}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
