import React, { useEffect, useState } from 'react';

import private0101 from '../assets/images/privateevents/01-01-privateevents.webp';
import private0201 from '../assets/images/privateevents/02-01-privateevents.webp';

import private0301 from '../assets/images/privateevents/03-01-privateevents.webp';
import private0302 from '../assets/images/privateevents/03-02-privateevents.webp';
import private0303 from '../assets/images/privateevents/03-03-privateevents.webp';
import private0401 from '../assets/images/privateevents/04-01-privateevents.webp';
import private0501 from '../assets/images/privateevents/05-01-privateevents.webp';
import private0502 from '../assets/images/privateevents/05-02-privateevents.webp';
import private0503 from '../assets/images/privateevents/05-03-privateevents.webp';
import i18next from 'i18next';

const PrivateEvents = () => {
    const { t } = i18next;
    const [backgroundStyle, setBackgroundStyle] = useState();
    useEffect(() => {
        const calculateImageHeight = () => {
            const imageElement = document.getElementById('image-1');
            const wrapper = document.getElementById('image-wrapper');

            if (imageElement && wrapper) {
                const imageRect = imageElement.getBoundingClientRect();
                const imageHeight = imageRect.height;
                const startY = 0;
                const endY = imageHeight;

                setBackgroundStyle({
                    background: `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`,
                });
            }
        };

        const handleResize = () => {
            calculateImageHeight();
        };

        calculateImageHeight();
        window.addEventListener('resize', handleResize);

        // Resim yüklendiğinde de hesaplamayı yap
        const imageElement = document.getElementById('image-1');
        if (imageElement.complete) {
            calculateImageHeight();
        } else {
            imageElement.addEventListener('load', calculateImageHeight);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            imageElement.removeEventListener('load', calculateImageHeight);
        };
    }, []);

    return (
        <div className='text-[#303030] flex flex-col items-center'>

            {/* 1. Container */}
            <div id="image-wrapper" style={backgroundStyle} className='text-[#303030] h-auto w-screen flex justify-center text-center'>
                <div className='mt-28 tablet:mt-44 mb-2 desktop:mb-20 tablet:mb-4 flex flex-col items-center justify-center overscreen:w-[106rem]'>
                    <h2 className='desktop:text-[54px] tablet:text-[38px] phone:text-[30px] font-thin leading-[53.76px] tracking-[3px]'>{t("privateEvents")}</h2>
                    <div className='phone:flex phone:justify-center'>
                        <img id='image-1' className="tablet:pt-6 pb-6 phone:pt-2 phone:m-0" src={private0101} alt='view of hotel' />
                    </div>
                    <div className='desktop:w-[60%] tablet:w-[90%] phone:w-[92%] mx-8'>
                        <h2 className='text-4xl tracking-1 font-light mb-4 leading-[36px] mt-4'>
                            {t("welcomeExclusive")}

                        </h2>
                        <h3 className='text-xl font-extralight mb-8 leading-[24px] tracking-[1px]'>
                            {t("primusInvite")}
                        </h3>
                    </div>
                </div>
            </div>


            {/* 2. Container */}
            <div className="h-auto w-screen bg-[#1C242E] flex justify-center items-center">
                <div className='text-[#F7F7F7] tablet:p-8 phone:p-0 tablet:text-left phone:text-center overscreen:w-[106rem] '>
                    <div className='flex tablet:flex-row tablet:justify-between items-center phone:justify-center phone:flex-col'>
                        <div className='tablet:w-[40%] tablet:mr-10 phone:w-[100%] phone:m-0 phone:flex phone:justify-center'>
                            <img className="tablet:pt-6 pb-6 phone:pt-[30px] phone:px-[16px]" src={private0201} alt='Sustainable Amenities' />
                        </div>
                        <div className='w-[90%] tablet:w-[60%] desktop:ml-10 desktop:pr-24 tablet:ml-0 tablet:pr-0'>
                            <h2 className='text-[38px] desktop:text-[44px] tracking-1 font-extralight mb-4 leading-[45px] tracking-[3px]'>
                                {t("memories")}
                            </h2>
                            <p className='text-xl font-extralight mb-8 leading-[28px]'>
                                {t("beyond")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* 3. Container */}
            <div className='px-[24.5px] my-[40px] tablet:my-[60px] desktop:my-[100px]'>
                <div className="w-[22%] border-b-2 border-[#9E8772] mb-8 ml-auto" />

                <div className="flex w-full justify-center tablet:text-left phone:text-center mx-auto">
                    {/* 1. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={private0301} alt='private events - 1' />
                    </div>

                    {/* 2. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={private0302} alt='private events - 2' />
                    </div>

                    {/* 3. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={private0303} alt='private events - 3' />
                    </div>
                </div>
                <div className="w-[22%] border-b-2 border-[#9E8772] mt-8 " />
            </div>


            {/* 4. Container */}
            <div className="h-auto w-screen bg-[#F4EEEB] flex justify-center items-center">
                <div className='text-[#303030] tablet:p-8 phone:p-0 tablet:text-left phone:text-center overscreen:w-[106rem] '>
                    <div className='flex tablet:flex-row tablet:justify-between items-center phone:justify-center phone:flex-col-reverse'>

                        <div className='w-[90%] tablet:w-[60%] desktop:ml-10 tablet:ml-4 phone:ml-0 desktop:pr-24 tablet:pr-12 phone:pr-0 '>
                            <h2 className='text-[38px] desktop:text-[44px] tracking-1 font-extralight mb-4 leading-[45px] tracking-[3px]'>
                                {t("yourVision")}
                            </h2>
                            <h3 className='text-xl font-extralight mb-8 leading-[28px]'>
                                {t("collaborate")}
                            </h3>
                        </div>
                        <div className='tablet:w-[40%] tablet:mr-10 phone:w-[100%] phone:m-0 phone:flex phone: justify-center'>
                            <img className="tablet:pt-6 pb-6 phone:pt-[30px] phone:px-[16px]" src={private0401} alt='Sustainable Amenities' />
                        </div>
                    </div>
                </div>
            </div>


            {/* 5. Container */}
            <div className='px-[24.5px] mt-[40px] tablet:mt-[60px] desktop:mt-[100px]'>
                <div className="w-[22%] border-b-2 border-[#9E8772] mb-8 " />
                <div className="flex w-full justify-center tablet:text-left phone:text-center mx-auto">
                    {/* 1. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={private0501} alt='view of hotel' />
                    </div>

                    {/* 2. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={private0502} alt='view of hotel' />
                    </div>

                    {/* 3. Image */}
                    <div className='flex flex-col items-center m-0 phone:m-1 tablet:m-2 desktop:m-4'>
                        <img className='w-full object-cover' src={private0503} alt='view of hotel' />
                    </div>
                </div>
                <div className="w-[22%] border-b-2 border-[#9E8772] mt-8 ml-auto" />
            </div>

        </div>
    );
}

export default PrivateEvents;
