import React, { useEffect } from 'react';

import home0101 from '../assets/images/home/01-01-home.webp'
import i18next from 'i18next';

const PrivacyPolicy = () => {
    const { t } = i18next;
    // calculate containers center coordinates
    const calculateBackground = () => {
        const firstContainer = document.getElementById('image-1');
        const lastContainer = document.getElementById('image-4');
        const wrapper = document.getElementById('image-wrapper');

        if (firstContainer && lastContainer && wrapper) {
            const wrapperRect = wrapper.getBoundingClientRect();
            const firstContainerRect = firstContainer.getBoundingClientRect();
            const lastContainerRect = lastContainer.getBoundingClientRect();
            const startY = firstContainerRect.top - wrapperRect.top + firstContainerRect.height / 2;
            const endY = lastContainerRect.top - wrapperRect.top + lastContainerRect.height / 2;
            wrapper.style.background = `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`;
        }
    };

    useEffect(() => {
        calculateBackground(); // Sayfa yüklendiğinde hesapla
        const handleResize = () => {
            calculateBackground(); // Ekran boyutu değiştiğinde hesapla
        };
        window.addEventListener('resize', handleResize); // Event listener 
        return () => {
            window.removeEventListener('resize', handleResize); // Temizleme işlemleri
        };
    }, []);

    return (
        <div className='text-[#303030] flex flex-col items-center'>

            {/* 1. Container */}
            <div className='px-[24.5px] overscreen:w-[106rem] text-[#303030]'>
                <div>
                    <img className="ml-0 pt-24" src={home0101} alt='view of hotel' />
                </div>

                <div>
                    <h2 className='border-b border-[#9E8772] border-0 phone:text-[26px] tablet:text-[32px] mt-[32px] phone:leading-[27px] tablet:leading-[30px] font-light text-[#1C242E] tracking-[0.8px] pb-2 '>
                        {t("privacyPolicyPrimus")}
                    </h2>
                </div>

                <p className='phone:text-[12px] tablet:text-[18px] mt-[20px] font-extralight phone:leading-4 tablet:leading-8'>
                    {t("privacyPolicyP1")}
                    <br />
                    {t("privacyPolicyP2")}
                    <br />
                    {t("privacyPolicyP3")}
                    <br />
                    {t("privacyPolicyP4")}
                    <br />
                    {t("privacyPolicyP5")}
                    <ul className="list-disc list-inside px-2">
                        <li>{t("privacyPolicyLi1")}</li>
                        <li>{t("privacyPolicyLi2")}</li>
                        <li>{t("privacyPolicyLi3")}</li>
                        <li>{t("privacyPolicyLi4")}</li>
                    </ul>
                    {t("privacyPolicyP6")}
                    <br />
                    {t("privacyPolicyP7")}
                    <br />
                    {t("privacyPolicyP8")}
                    <br />
                    {t("privacyPolicyP9")}

                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;