
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useEffect, useState } from 'react';

import { MainContext, useContext } from '../Context';

import CloseMenuIcon from '../assets/svg/04-icon-closemenu.svg';
import InstaIcon from '../assets/svg/11-icon-menuLinks-instagram.svg';
import i18next from '../i18n'
import downArrow from '../assets/svg/03-icon-arrow-white.svg'
import Search from './Search';


const MenuLinks = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const { t } = i18next;
    const { handleMenuToggle, selectLanguage } = useContext(MainContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const menuAnimation = useSpring({
        from: { transform: isMobile ? 'translateY(-100%)' : 'translateX(-100%)' },
        to: { transform: 'translateY(0%)' },
    });

    return (
        <animated.div
            style={menuAnimation}
            className='typing-animation-container text-[#F7F7F7] pl-[40px] pt-[67px] pb-[37px] pr-[31px] desktop:w-96 phone:w-screen h-full ml-0 bg-[#1C242E] flex-col justify-between overflow-auto flex'
        >
            <div >
                <button onClick={handleMenuToggle}>
                    <img src={CloseMenuIcon} alt='close icon'></img>
                </button>
                <div className='flex-col space-y-10'>
                    <div>

                        <Link to="/" onClick={() => { handleMenuToggle();}}>
                            <h1 className="text-2xl pt-[42px] pb-[35px] tracking-[8px] font-semibold cursor-pointer">{t('primusH1')}</h1>
                        </Link>
                        <div className='tablet:hidden phone:flex pb-[35px]'>
                            <Search colorOfSearch='white' />
                        </div>

                        <div>
                            <ul>
                                <li className='pb-[20px] hover:opacity-50'><Link to="/room" onClick={() => { handleMenuToggle();}}>{t('roomsAndSuites')}</Link></li>
                                <li className='pb-[20px] hover:opacity-50'><Link to="/restaurant" onClick={() => { handleMenuToggle();}}>{t('restaurant')}</Link></li>
                                <li className='pb-[20px] hover:opacity-50'><Link to="/privateevents" onClick={() => { handleMenuToggle();}}>{t('privateEvents')}</Link></li>
                                <li className='pb-[20px] hover:opacity-50'><Link to="/sustainability" onClick={() => { handleMenuToggle();}}>{t('sustainability')}</Link></li>
                                <li className='pb-[20px] hover:opacity-50'><Link to="/about" onClick={() => { handleMenuToggle();}}>{t('aboutUs')}</Link></li>
                                <li className='pb-[20px] hover:opacity-50'><Link to="/contact" onClick={() => { handleMenuToggle();}}>{t('contact')}</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className='pt-[40px]'>{t('new')}</h3>
                            <div className="w-[150px] h-px border-b bg-[#F7F7F7]" />

                            <div className='flex flex-col pt-[20px] pb-10'>
                                <h3 className='text-lg mb-[5px]'>{t('comingSoon1')}</h3>
                                <p className='text-lg font-bold'>{t('comingSoon')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className='flex justify-between pb-8'>

                <div className="relative group">
                    <button
                        id="dropdown-button"
                        onClick={toggleDropdown}
                        className="inline-flex justify-center w-full py-1 text-[18px] tracking-[1px] font-medium text-[#F7F7F7]"
                    >
                        <span className="mr-4">{i18next.language === 'en' ? 'EN' : 'TR'}</span>
                        <img alt='arrow' className="w-4 h-4 ml-2 mx-auto mt-1" src={downArrow} />

                    </button>
                    <div
                        id="dropdown-menu"
                        className={`absolute mt-2 
                                    ${isOpen ? 'block' : 'hidden'}`}
                    >
                        {i18next.language === 'tr' ? (
                            <div onClick={() => selectLanguage('en')} className="block text-[18px] text-[#F7F7F7] cursor-pointer tracking-[0.5px]">EN</div>) : (
                            <div onClick={() => selectLanguage('tr')} className="block text-[18px] text-[#F7F7F7] cursor-pointer tracking-[0.5px]">TR</div>)
                        }
                    </div>
                </div>

                <div className='tablet:hidden visible'>
                    <a href='https://www.instagram.com/primusotel/' target='_blank' rel='noopener noreferrer' className='cursor-pointer flex items-center'>
                        <img
                            src={InstaIcon}
                            alt="Instagram Icon"
                            className='transition-transform w-[22px] hover:scale-110 ml-2 mr-6'
                        />
                    </a>
                </div>
            </div>
        </animated.div>
    );
}

export default MenuLinks;
