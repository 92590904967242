import React, { useRef } from 'react'
import CompanyLogo from '../assets/images/103-logo.png'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

const Footer = () => {
    const { t } = i18next;
    const formRef = useRef();

    const handleClick = () => {
        const formElement = document.getElementById('contactformref');

        if (formElement) {
            const offsetTop = formElement.offsetTop;
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <div className="h-auto w-screen bg-[#1C242E] flex justify-center items-center phone:mt-[50px] desktop:mt-[150px]">

            <div className='bg-[#1C242E] text-[#F7F7F7] w-full pb-10 overscreen:w-[106rem]' >
                <div className='flex flex-col mx-[37.5px] desktop:mx-[49.5px] tablet:mx-[49.5px]'>

                    {/* 1. Section */}
                    <div className='flex desktop:flex-row phone:flex-col space-y-6 space-x-6 phone:space-x-0 tablet:space-y-6 desktop:justify-between tablet:justify-between items-center phone:justify-center mt-[60px]'>
                        <a href="/" className='flex justify-center desktop:hidden tablet:flex'>
                            <img
                                className='transition-all duration-500 transform scale-130 tablet:ml-8 phone:ml-4 mb-9 z-[20]'
                                src={CompanyLogo}
                                alt='hotel logo'
                            />
                        </a>
                        <div className='flex items-center flex-col tablet:flex-row tablet:w-[50%] phone:w-[100%]'>
                            <div className='flex phone:flex-col desktop:flex-row tablet:pb-0 phone:pb-10'>
                                <h3 className='text-xl font-extralight leading-[30px] tracking-[1px] text-center desktop:text-left'>
                                    {t("receiveFooter")}
                                </h3>
                            </div>
                        </div>
                        <Link to="/contact" >
                            <button className='w-[300px] h-[35px] bg-[#F7F7F7] text-[#1C242E]'>
                                {t("contact")}
                            </button>
                        </Link>
                    </div>

                    {/* 2. Section */}
                    <div className='items-center flex desktop:flex-row phone:flex-col desktop:justify-between phone:justify-center mt-[30px]'>

                        <a href="/" className='phone:hidden tablet:hidden desktop:block overscreen:block'>
                            <img
                                className='transition-all duration-500'
                                src={CompanyLogo}
                                alt='hotel logo'
                            />
                        </a>

                        <div className='flex flex-col tablet:flex-row tablet:items-start phone:items-center justify-between space-x-0 desktop:space-x-16 tablet:space-x-12 mx-10'>
                            <div className='flex flex-col items-center'>
                                <h3 className='text-[18px] font-extralight leading-[20px] my-5 tracking-[2.34px]'>{t("explore")}</h3>
                                <Link to="/about"><h4 className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("aboutUs")}</h4></Link>
                                <Link to="/sustainability"><h4 className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("sustainability")}</h4></Link>
                                <Link to="/privateevents"><h4 className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("privateEvents")}</h4></Link>
                            </div>
                            <div className='flex flex-col items-center'>
                                <h3 className='text-[18px] font-extralight leading-[20px] my-5 tracking-[2.34px]'>{t("connect")}</h3>

                                <Link onClick={() => formRef.current && formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })} to="/contact?scrollTo=contactformref">
                                    <h4 onClick={handleClick} className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("contact1")}</h4>
                                </Link>

                                <a href='https://www.instagram.com/primusotel/' target='_blank' rel='noopener noreferrer'>
                                    <h4 className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("contact2")}</h4>
                                </a>
                            </div>
                            <div className='flex flex-col items-center'>
                                <h3 className='text-[18px] font-extralight leading-[20px] my-5 tracking-[2.34px]'>{t("links")}</h3>
                                <Link to="/termsofuse"><h4 className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("termsOfUseFooter")}</h4></Link>
                                <Link to="/privacypolicy"><h4 className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("privacyPolicy2")}</h4></Link>
                                <Link to="/cookiepolicy"><h4 className='pb-2 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>{t("cookiePolicy")}</h4></Link>
                            </div>
                        </div>

                        <h3 className='pt-8 leading-[19px] tracking-[0.28px] cursor-pointer font-light'>
                            {t("copyright")}
                        </h3>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer