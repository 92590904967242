import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Home from '../screens/Home';
import About from '../screens/About';
import Contact from '../screens/Contact';
import Room from '../screens/Room';
import Restaurant from '../screens/Restaurant';
import NoMatch from '../components/NoMatch';
import Sustainability from '../screens/Sustainability';
import PrivateEvents from '../screens/PrivateEvents';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import TermsOfUse from '../screens/TermsOfUse';
import CookiePolicy from '../screens/CookiePolicy';

const SiteNavigation = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/contact') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);

  return (
    <div>
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/room" element={<Room />} />
        <Route path="/restaurant" element={<Restaurant />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/privateevents" element={<PrivateEvents />} />

        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/cookiepolicy" element={<CookiePolicy />} />

        <Route path="*" element={<NoMatch />} />

      </Routes>
    </div>
  );
};

export default SiteNavigation;
