import { useState, useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { MainContext } from './Context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header';
import SiteNavigation from './navigation/SiteNavigation';
import MenuLinks from './components/MenuLinks';
import Footer from './components/Footer';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [bgHeader, setBgHeader] = useState('');

  const handleMenuToggle = () => {
    setIsMenuOpen(prevState => !prevState);
  };
  const selectLanguage = (val) => {
    localStorage.setItem('language', val);
    window.location.reload();
  }

  const data = {
    isMenuOpen,
    handleMenuToggle,
    bgHeader,
    setBgHeader,
    selectLanguage,
    setIsMenuOpen
  }

  //   Koordinatlar :::
  //       Header:            z + 100
  //       Footer:            z + 100

  //       Filtre:            z + 100
  //       MenuLinks:         z + 101
  //       Tüm Bileşenler:    z + 099

  return (
    <div>
      <BrowserRouter>
        <MainContext.Provider value={data}>
          <div>
            <Header />
            {isMenuOpen && (
              <>
                {/* Filter Katmanı */}
                <div onClick={handleMenuToggle} className="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-50 z-[100] transition-opacity duration-500"></div>
                {/* Açılan Menü */}
                <div className='fixed top-0 left-0 w-200 h-full bg-[#1C242E] z-[101]'>
                  <MenuLinks />
                </div>
              </>
            )}
            <SiteNavigation />
            <Footer />
          </div>
        </MainContext.Provider >
      </BrowserRouter >
      <ToastContainer />
    </div>
  );
}

export default App;
