import React, { useEffect, useState } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import i18next from '../i18n'
import InstaIcon from '../assets/svg/05-icon-instagram.svg';

const CustomInstagramEmbed = ({ width, height }) => {
    return (
        <div className={`w-${width} h-${height} z-10 relative`}>
            <InstagramEmbed url='https://www.instagram.com/primusotel/' width={width} height={height} />
        </div>
    );
};

const Instagram = () => {
    const { t } = i18next;

    const [widthInsta, setWidthInsta] = useState(325);
    const [heightInsta, setHeightInsta] = useState(330);
    const [mark, setMark] = useState(110);

    const updateImageIndexes = () => {
        const screenWidth = window.innerWidth;
        let newWidth, newHeight, newMark;
        if (screenWidth < 768) {
            newWidth = 325;
            newHeight = 325;
            newMark = 110;
        } else if (screenWidth < 1024) {
            newWidth = 500;
            newHeight = 450;
            newMark = 141;
        } else {
            newWidth = 800;
            newHeight = 660;
            newMark = 141;
        }
        setWidthInsta(newWidth);
        setHeightInsta(newHeight);
        setMark(newMark);
    };

    useEffect(() => {
        updateImageIndexes();
        window.addEventListener('resize', updateImageIndexes);
        return () => {
            window.removeEventListener('resize', updateImageIndexes);
        };
    }, []);
    // F7F7F7 ARKA PLAN

    return (
        <div className='px-[24.5px] flex flex-col items-center'>

            {/* 7. Container */}
            <div className='mt-10 flex justify-center items-center text-center desktop:flex-row phone:flex-col-reverse desktop:mx-[154.5px] tablet:mx-0 z-[50]'>

                <h1 className="text-[#1C242E] tablet:text-[36px] phone:text-[28px] font-normal leading-[40px] tracking-[0.98px] font-spartan w-[80%]">
                    {t("instagramText_1")} <a href='https://www.instagram.com/primusotel/' className='cursor-pointer hover:underline'>{t("instagramText_2")}</a>
                </h1>
                <a href='https://www.instagram.com/primusotel/' target='_blank' rel='noopener noreferrer' className='cursor-pointer tablet:ml-[25px] phone:ml-0 desktop:mb-0 phone:mb-8'>
                    <img
                        src={InstaIcon}
                        alt="Instagram Icon"
                        className='transition-transform hover:scale-110'
                    />
                </a>
            </div>

            <div className='relative -mt-20'>
                <div style={{ height: `${mark}px` }} className="absolute top-0 w-full bg-[#F7F7F7] z-20"></div>
                <CustomInstagramEmbed width={widthInsta} height={heightInsta} className='z-10' />
            </div>
        </div>
    );
};

export default Instagram;
