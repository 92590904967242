import React, { useEffect, useState } from 'react';

import about0101 from '../assets/images/about/01-01-about.webp';
import about0201 from '../assets/images/about/02-01-about.webp';
import about0202 from '../assets/images/about/02-02-about.webp';
import about0203 from '../assets/images/about/02-03-about.webp';
import about0204 from '../assets/images/about/02-04-about.webp';
import about0205 from '../assets/images/about/02-05-about.webp';
import about0301 from '../assets/images/about/03-01-about.webp';
import about0302 from '../assets/images/about/03-02-about.webp';
import about0303 from '../assets/images/about/03-03-about.webp';
import i18next from '../i18n'

const About = () => {
  const [backgroundStyle, setBackgroundStyle] = useState();
  const { t } = i18next;

  useEffect(() => {
    const calculateImageHeight = () => {
      const imageElement = document.getElementById('image-1');
      const wrapper = document.getElementById('image-wrapper');

      if (imageElement && wrapper) {
        const imageRect = imageElement.getBoundingClientRect();
        const imageHeight = imageRect.height;
        const startY = 0;
        const endY = imageHeight;
        setBackgroundStyle({
          background: `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`,
        });
      }
    };

    const handleResize = () => {
      calculateImageHeight();
    };

    // Sayfa yüklendiğinde ve pencere boyutu değiştiğinde hesaplamayı yap
    calculateImageHeight();
    window.addEventListener('resize', handleResize);

    // Resim yüklendiğinde de hesaplamayı yap
    const imageElement = document.getElementById('image-1');
    if (imageElement.complete) {
      calculateImageHeight();
    } else {
      imageElement.addEventListener('load', calculateImageHeight);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      imageElement.removeEventListener('load', calculateImageHeight);
    };
  }, []);
  return (
    <div className='text-[#303030] flex flex-col items-center'>


      {/* 1. Container */}
      <div id="image-wrapper" style={backgroundStyle} className='text-[#303030] h-auto w-screen flex justify-center text-center'>
        <div className='mt-28 tablet:mt-44 mb-2 desktop:mb-20 tablet:mb-4 flex flex-col items-center justify-center overscreen:w-[106rem]'>
          <h2 className='desktop:text-[54px] tablet:text-[38px] phone:text-[30px] font-thin leading-[53.76px] tracking-[3px]'>{t("aboutUs")}</h2>
          <div className='phone:flex phone:justify-center'>
            <img id='image-1' className="tablet:pt-6 pb-6 phone:pt-2 phone:m-0" src={about0101} alt='view of hotel 1' />
          </div>
          <div className='desktop:w-[60%] tablet:w-[90%] phone:w-[92%] mx-8'>
            <p className='text-xl font-extralight mb-8 leading-[24px] tracking-[1px]'>
              {t("aboutFirstDescription")}
            </p>
          </div>
        </div>
      </div>


      {/* 2. Container */}
      <div className='overscreen:w-[106rem] desktop:mx-20 justify-center tablet:mx-8 flex tablet:flex-wrap tablet:flex-row phone:flex-col'>
        {/* 1. Image */}
        <div className='text-center flex justify-center desktop:w-full phone:w-full tablet:w-[50%] desktop:h-full phone:h-full tablet:h-[50%]'>
          <div className='flex desktop:flex-row tablet:flex-col phone:flex-col desktop:justify-between tablet:justify-center phone:justify-center items-center'>
            <img className='desktop:aspect-auto phone:aspect-square object-cover desktop:p-6 phone:p-4' src={about0201} alt='view of hotel - 2' />

            <h2 className="text-[#303030] mx-6 text-[22px] font-extralight leading-[25px] tracking-[0.8px]">
              {t("aboutSecondDescription")}

            </h2>
          </div>
        </div>
        {/* 2. Image */}
        <div className='text-center flex justify-center desktop:w-full phone:w-full tablet:w-[50%] desktop:h-full phone:h-full tablet:h-[50%]'>
          <div className='flex desktop:flex-row tablet:flex-col-reverse phone:flex-col-reverse desktop:justify-between tablet:justify-center phone:justify-center items-center'>
            <h2 className="text-[#303030] mx-6 text-[22px] font-extralight leading-[25px] tracking-[0.8px]">
              {t("aboutThirdDescription")}
            </h2>
            <img className='desktop:aspect-auto phone:aspect-square object-cover desktop:p-6 phone:p-4' src={about0202} alt='view of hotel - 3' />
          </div>
        </div>
        {/* 3. Image */}
        <div className='text-center flex justify-center desktop:w-full phone:w-full tablet:w-[50%] desktop:h-full phone:h-full tablet:h-[50%]'>
          <div className='flex desktop:flex-row tablet:flex-col phone:flex-col desktop:justify-between tablet:justify-center phone:justify-center items-center'>
            <img className='desktop:aspect-auto phone:aspect-square object-cover desktop:p-6 phone:p-4' src={about0203} alt='view of hotel - 4' />
            <h2 className="text-[#303030] mx-6 text-[22px] font-extralight leading-[25px] tracking-[0.8px]">
              {t("about4thDescription")}
            </h2>
          </div>
        </div>
        {/* 4. Image */}
        <div className='text-center flex justify-center desktop:w-full phone:w-full tablet:w-[50%] desktop:h-full phone:h-full tablet:h-[50%]'>
          <div className='flex desktop:flex-row tablet:flex-col-reverse phone:flex-col-reverse desktop:justify-between tablet:justify-center phone:justify-center items-center'>
            <h2 className="text-[#303030] mx-6 text-[22px] font-extralight leading-[25px] tracking-[0.8px]">
              {t("about5thDescription")}
            </h2>
            <img className='desktop:aspect-auto phone:aspect-square object-cover desktop:p-6 phone:p-4' src={about0204} alt='view of hotel - 5' />
          </div>
        </div>
        {/* 5. Image */}
        <div className='text-center flex justify-center desktop:w-full phone:w-full tablet:w-[50%] desktop:h-full phone:h-full tablet:h-[50%]'>
          <div className='flex desktop:flex-row tablet:flex-col phone:flex-col desktop:justify-between tablet:justify-center phone:justify-center items-center'>
            <img className='desktop:aspect-auto phone:aspect-square object-cover desktop:p-6 phone:p-4' src={about0205} alt='view of hotel - 6' />
            <h2 className="text-[#303030] mx-6 text-[22px] font-extralight leading-[25px] tracking-[0.8px]">
              {t("about6thDescription")}

            </h2>
          </div>
        </div>

      </div>

      {/* 3. Container */}
      <div className='px-[24.5px] mt-[30px]'>
        <h2 className="text-[#1C242E] text-[26px] font-light tracking-[2px] mb-2 tablet:mb-[60px] text-center">
          {t("discoverMore")}
        </h2>
        <div className="flex flex-wrap justify-center text-center">
          {/* 1. Image */}
          <div className='flex flex-col items-center m-4 desktop:w-[26.5rem] tablet:w-[32rem] mb-4'>
            <img className='w-full object-cover' src={about0301} alt='view of hotel - 7' />
            <div className='pb-8 h-[190px] bg-[#FFFFFF]'>

              <h2 className="text-[#1C242E] mb-2 text-[22px] font-light leading-6 tracking-[0.98px] font-spartan my-6">
                {t("cocktail")} <br />
              </h2>
              <h2 className="text-[#303030] text-[18px] font-extralight leading-[20.3px] tracking-[0.8px] mx-4">
                {t("elevatePrivateEvents")}
              </h2>
            </div>
          </div>

          {/* 2. Image */}
          <div className='flex flex-col items-center m-4 desktop:w-[26.5rem] tablet:w-[32rem] mb-4'>
            <img className='w-full object-cover' src={about0302} alt='view of hotel - 8' />
            <div className='pb-8 h-[190px] bg-[#FFFFFF]'>

              <h2 className="text-[#1C242E] mb-2 text-[22px] font-light leading-6 tracking-[0.98px] font-spartan my-6">
                {t("firesideBliss")}
              </h2>
              <h2 className="text-[#303030] text-[18px] font-extralight leading-[20.3px] tracking-[0.8px] mx-4">
                {t("immerseFirePlace")}
              </h2>
            </div>
          </div>

          {/* 3. Image */}
          <div className='flex flex-col items-center m-4 desktop:w-[26.5rem] tablet:w-[32rem] mb-4'>
            <img className='w-full object-cover' src={about0303} alt='view of hotel - 9' />
            <div className='pb-8 h-[190px] bg-[#FFFFFF]'>

              <h2 className="text-[#1C242E] mb-2 text-[22px] font-light leading-6 tracking-[0.98px] font-spartan my-6">
                {t("timelessMoments")}
              </h2>
              <h2 className="text-[#303030] text-[18px] font-extralight leading-[20.3px] tracking-[0.8px] mx-4">
                {t("sophisticatedAmbiance")}
              </h2>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default About;
