import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import room0101 from '../assets/images/room/roomcard/01-01-roomcard-p.webp';
import room0102 from '../assets/images/room/roomcard/01-02-roomcard-p.webp';
import room0103 from '../assets/images/room/roomcard/01-03-roomcard-p.webp';
import room0104 from '../assets/images/room/roomcard/01-04-roomcard-p.webp';
import room0105 from '../assets/images/room/roomcard/01-05-roomcard-p.webp';

import room0201 from '../assets/images/room/roomcard/02-01-roomcard-p.webp';
import room0202 from '../assets/images/room/roomcard/02-02-roomcard-p.webp';
import room0203 from '../assets/images/room/roomcard/02-03-roomcard-p.webp';
import room0204 from '../assets/images/room/roomcard/02-04-roomcard-p.webp';
import room0205 from '../assets/images/room/roomcard/02-05-roomcard-p.webp';

import room0301 from '../assets/images/room/roomcard/03-01-roomcard-p.webp';
import room0302 from '../assets/images/room/roomcard/03-02-roomcard-p.webp';
import room0303 from '../assets/images/room/roomcard/03-03-roomcard-p.webp';
import room0304 from '../assets/images/room/roomcard/03-04-roomcard-p.webp';
import room0305 from '../assets/images/room/roomcard/03-05-roomcard-p.webp';

import room0401 from '../assets/images/room/roomcard/04-01-roomcard-p.webp';
import room0402 from '../assets/images/room/roomcard/04-02-roomcard-p.webp';
import room0403 from '../assets/images/room/roomcard/04-03-roomcard-p.webp';
import room0404 from '../assets/images/room/roomcard/04-04-roomcard-p.webp';
import room0405 from '../assets/images/room/roomcard/04-05-roomcard-p.webp';

import room0501 from '../assets/images/room/roomcard/05-01-roomcard-p.webp';
import room0502 from '../assets/images/room/roomcard/05-02-roomcard-p.webp';
import room0503 from '../assets/images/room/roomcard/05-03-roomcard-p.webp';
import room0504 from '../assets/images/room/roomcard/05-04-roomcard-p.webp';
import room0505 from '../assets/images/room/roomcard/05-05-roomcard-p.webp';

import i18next from '../i18n'
import { useLocation } from 'react-router-dom';

const RoomCard = ({ elementRefs }) => {
    const { t } = i18next;
    const location = useLocation();
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const scrollToElement = urlParams.get('scrollTo');

        if (scrollToElement) {
            const element = document.getElementById(scrollToElement);
            if (element) {
                setShouldScroll(true);
            }
        }
    }, [location.search]);

    useEffect(() => {
        if (shouldScroll) {
            const urlParams = new URLSearchParams(location.search);
            const scrollToElement = urlParams.get('scrollTo');
            const element = document.getElementById(scrollToElement);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                setShouldScroll(false);
            }
        }
    }, [shouldScroll, location.search]);

    // calculate containers center coordinates
    useEffect(() => {
        const calculateBackgroundC = () => {
            const firstContainer = document.getElementById('imageroom-1');
            const lastContainer = document.getElementById('imageroom-5');
            const wrapper = document.getElementById('image-wrapper-room');

            if (firstContainer && lastContainer && wrapper) {
                const wrapperRect = wrapper.getBoundingClientRect();
                const firstContainerRect = firstContainer.getBoundingClientRect();
                const lastContainerRect = lastContainer.getBoundingClientRect();
                const startY = firstContainerRect.top - wrapperRect.top + firstContainerRect.height / 2;
                const endY = lastContainerRect.top - wrapperRect.top + lastContainerRect.height / 2;
                wrapper.style.background = `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`;
            }
        };
        const handleResize = () => {
            calculateBackgroundC();
        };

        calculateBackgroundC();
        window.addEventListener('resize', handleResize);

        const imageElement1 = document.getElementById('imageroom-1');
        const imageElement2 = document.getElementById('imageroom-5');
        if (imageElement1.complete && imageElement2.complete) {
            calculateBackgroundC();
        } else {
            imageElement1.addEventListener('load', calculateBackgroundC);
            imageElement2.addEventListener('load', calculateBackgroundC);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            imageElement1.removeEventListener('load', calculateBackgroundC);
            imageElement2.removeEventListener('load', calculateBackgroundC);
        };
    }, []);

    const data = [
        {
            image: room0101,
            title: t("room1CardTitle"),
            paragraph_one: t("room1CardParagraph_one"),
            paragraph_two: t("room1CardParagraph_two"),
            roomAllImages: [room0101, room0102, room0103, room0104, room0105],
            roomImages: [room0102, room0103, room0104, room0105],
        },
        {
            image: room0201,
            title: t("room2CardTitle"),
            paragraph_one: t("room2CardParagraph_one"),
            paragraph_two: t("room2CardParagraph_two"),
            roomAllImages: [room0201, room0202, room0203, room0204, room0205],
            roomImages: [room0202, room0203, room0204, room0205],
        },
        {
            image: room0301,
            title: t("room3CardTitle"),
            paragraph_one: t("room3CardParagraph_one"),
            paragraph_two: t("room3CardParagraph_two"),
            roomAllImages: [room0301, room0302, room0303, room0304, room0305],
            roomImages: [room0302, room0303, room0304, room0305],
        },
        {
            image: room0401,
            title: t("room4CardTitle"),
            paragraph_one: t("room4CardParagraph_one"),
            paragraph_two: t("room4CardParagraph_two"),
            paragraph_three: t("room4CardParagraph_three"),
            roomAllImages: [room0401, room0402, room0403, room0404, room0405],
            roomImages: [room0402, room0403, room0404, room0405],
        },
        {
            image: room0501,
            title: t("room5CardTitle"),
            paragraph_one: t("room5CardParagraph_one"),
            paragraph_two: t("room5CardParagraph_two"),
            roomAllImages: [room0501, room0502, room0503, room0504, room0505],
            roomImages: [room0502, room0503, room0504, room0505],
        },
    ];

    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [currentRoomIndex, setCurrentRoomIndex] = useState(null);

    const openSlider = (index) => {
        setCurrentRoomIndex(index);
        setIsSliderOpen(true);
    };

    const closeSlider = () => {
        setIsSliderOpen(false);
        setCurrentRoomIndex(null);
    };

    return (
        <div id="image-wrapper-room" className="h-auto w-screen flex justify-center items-center mt-2">
            <div className='mx-auto overscreen:w-[106rem]'>
                <div className="flex flex-wrap desktop:flex-row tablet:flex-wrap phone:flex-col justify-center mt-10">
                    {data.map((room, index) => (
                        <div
                            key={index}
                            ref={elementRefs[index + 1]}
                            className='flex flex-col items-center text-center mb-16 desktop:mx-16 tablet:mx-4 phone:mx-0 desktop:w-[28rem] tablet:w-[22rem] phone:w-[20rem]'
                        >
                            <img
                                onClick={() => openSlider(index)}
                                id={`imageroom-${index + 1}`}
                                className='w-full object-cover cursor-pointer aspect-square desktop:p-8 tablet:p-3 phone:p-0'
                                src={room.image}
                                alt={`view of hotel ${index + 1}`}
                            />
                            <div>
                                <div className='desktop:h-[220px] tablet:h-[260px] phone:h-auto'>
                                    <h2 className='text-[32px] desktop:mt-7 phone:mt-6 font-light text-[#1C242E] leading-[26px] tracking-[2px] text-center'>
                                        {room.title}
                                    </h2>
                                    <div className='flex flex-col items-center'>
                                        <h3 className='phone:text-[14px] tablet:text-[16px] desktop:text-[18px] mt-5 font-light text-[#303030] leading-[20.3px] tracking-[2px] text-center'>
                                            {room.paragraph_one}
                                        </h3>
                                        <h3 className='phone:text-[14px] tablet:text-[16px] desktop:text-[18px]  mt-5 font-light text-[#303030] leading-[20.3px] tracking-[2px] text-center'>
                                            {room.paragraph_two}
                                        </h3>
                                        <h3 className='phone:text-[14px] tablet:text-[16px] desktop:text-[18px]  mt-5 mb-6 font-light text-[#303030] leading-[20.3px] tracking-[2px] text-center'>
                                            {room.paragraph_three}
                                        </h3>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex flex-wrap justify-center tablet:mt-20 phone:mt-2">
                                        {room.roomImages.map((image, imageIndex) => (
                                            <div key={imageIndex} className='w-full desktop:w-[50%] tablet:w-[40%] phone:w-[35%] p-2'>
                                                <img
                                                    className='w-full object-cover cursor-pointer'
                                                    src={image}
                                                    alt={`view of room ${imageIndex + 1}`}
                                                    onClick={() => openSlider(index)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {isSliderOpen && currentRoomIndex === index && (
                                <div className='fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center' onClick={closeSlider}>
                                    <div className='relative w-[750px] mx-4' onClick={(e) => e.stopPropagation()}>
                                        <button
                                            className='absolute right-4 text-black text-[50px] bg-transparent border-none z-[201]'
                                            onClick={closeSlider}
                                            style={{ width: '30px', height: '30px' }}
                                        >
                                            &times;
                                        </button>
                                        <Slider
                                            dots
                                            infinite
                                            speed={100}
                                            slidesToShow={1}
                                            slidesToScroll={1}
                                            initialSlide={0}
                                            className='z-[200] flex mx-auto phone:w-[40%] tablet:w-[85%] desktop:w-full'
                                        >
                                            {data[currentRoomIndex]?.roomAllImages.map((image, imageIndex) => (
                                                <div key={imageIndex}>
                                                    <img src={image} alt={`view of room ${imageIndex + 1}`} />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RoomCard;
