import React from 'react';

import home0101 from '../assets/images/home/01-01-home.webp'
import i18next from 'i18next';

const TermsOfUse = () => {
    const { t } = i18next;
    return (
        <div className='text-[#303030] flex flex-col items-center'>

            {/* 1. Container */}
            <div className='px-[24.5px] overscreen:w-[106rem] text-[#303030]'>
                <div>
                    <img className="ml-0 pt-24" src={home0101} alt='view of hotel' />
                </div>

                <div>
                    <h2 className='border-b border-[#9E8772] border-0 phone:text-[26px] tablet:text-[32px] mt-[32px] phone:leading-[27px] tablet:leading-[30px] font-light text-[#1C242E] tracking-[0.8px] pb-2 '>{t("termsOfUse")}</h2>
                </div>

                <h3 className='phone:text-[12px] tablet:text-[18px] mt-[20px] font-extralight phone:leading-4 tablet:leading-8'>
                    {t("welcomePrimusWebsite")}
                    <ol className="list-decimal list-inside px-1">
                        <li>
                            {t("useOfWebsite")}
                        </li>
                        <li>
                            {t("responsibilities")}
                        </li>
                        <li>
                            {t("intellectualProperty")}
                        </li>
                        <li>
                            {t("thirdParty")}
                        </li>
                        <li>
                            {t("privacy")}
                        </li>
                        <li>
                            {t("disclaimer")}
                        </li>
                        <li>
                            {t("changesTermsOf")}
                        </li>
                    </ol>
                    {t("changesTermsOfLast")}
                </h3>
            </div>
        </div>
    )
}

export default TermsOfUse;