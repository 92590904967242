import React, { useState, useEffect } from 'react';

import CompanyLogo from '../assets/images/102-logo.png';
import BurgerIcon from '../assets/svg/01-icon-burger.svg';
import InstaIcon from '../assets/svg/05-icon-instagram.svg';
import downArrow from '../assets/svg/03-icon-arrow.svg'

import { MainContext, useContext } from '../Context';
import i18next from '../i18n';
import Search from './Search';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const { handleMenuToggle, bgHeader, setBgHeader, selectLanguage } = useContext(MainContext);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [isHeaderSticky, setHeaderSticky] = useState(false);
    const [halfHeaderHeight, setHalfHeaderHeight] = useState(88);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;
            setScrollPosition(currentScrollPosition);
            const headerHeight = 88;
            if (currentScrollPosition > headerHeight) {
                setHalfHeaderHeight(headerHeight / 2);
                setHeaderSticky(true);
                updateBackground(0);
            } else {
                setHalfHeaderHeight(headerHeight);
                setHeaderSticky(false);
                updateBackground(1);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, [scrollPosition]);

    const updateBackground = (val) => {
        if (window.location.pathname === '/' || window.location.pathname === '/contact' || window.location.pathname === '/privacypolicy' || window.location.pathname === '/termsofuse' || window.location.pathname === '/cookiepolicy') {
            setBgHeader('#F7F7F7');
        } else {
            if (val === 1) setBgHeader('#F4EEEB');
            if (val === 0) setBgHeader('#F7F7F7');
        }
    };

    useEffect(() => {
        updateBackground(1);
        // eslint-disable-next-line
    }, [window.location.pathname]);

    const { t } = i18next;
    return (
        <div className='fixed h-auto w-screen flex justify-center z-[100]' style={{ backgroundColor: bgHeader }}>
            <div className='w-[100%] overscreen:w-[106rem]'>
                <div className="flex justify-between items-center w-full transition-all duration-500 pr-6" style={{ height: `${halfHeaderHeight}px` }}>
                    {/* Left Side */}
                    <div className="cursor-pointer flex items-center gap-[27px] ml-[24.5px] relative">
                        <img
                            onClick={handleMenuToggle}
                            src={BurgerIcon}
                            alt="Burger Icon"
                            className="phone:scale-150 hover:scale-125 transition-transform duration-300 ease-in-out"
                        />
                        <div className="cursor-pointer hidden tablet:flex" onClick={handleMenuToggle} >
                            <h4 className="text-gray-800 text-[18px] font-normal leading-tight tracking-wide">{t("menuHeader")}</h4>
                        </div>
                        <div className='ml-[25px] hidden tablet:flex'>
                            <Search colorOfSearch='#1C242E' />
                        </div>
                    </div>
                    {/* Middle Side */}
                    <a href="/" className='flex justify-center items-center desktop:pl-6 tablet:pl-0 desktop:pr-0 tablet:pr-16 phone:pr-0'>
                        <img
                            className={`transition-all duration-500 w-1/3 h-1/3 ${isHeaderSticky ? 'w-1/6 h-1/6' : ''}`}
                            src={CompanyLogo}
                            alt='hotel logo'
                        />
                    </a>
                    {/* Right Side */}
                    <div className='flex justify-end items-center'>
                        <div className="lg:flex hidden cursor-pointer flex-col justify-start items-start gap-[5px] mr-[40px]">
                            <div className="relative group w-[100px]">
                                <button
                                    id="dropdown-button"
                                    onClick={toggleDropdown}
                                    className="inline-flex justify-center w-full py-1 text-[18px] tracking-[1px] font-medium text-[#1C242E] border-[#1C242E] border-b-[1px] focus:outline-none focus:ring-0 focus:border-black"
                                >
                                    <span className="mr-4">{i18next.language === 'en' ? 'English' : 'Türkçe'}</span>
                                    <img alt='arrow' className="w-4 h-4 ml-2 mx-auto mt-1" src={downArrow} />
                                </button>
                                <div
                                    id="dropdown-menu"
                                    className={`absolute mt-2 w-full bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 
                                    ${isOpen ? 'block' : 'hidden'}`}
                                >
                                    {i18next.language === 'tr' ? (
                                        <div onClick={() => selectLanguage('en')} className="block px-2 py-1 text-gray-700 cursor-pointer tracking-[0.5px]">English</div>) : (
                                        <div onClick={() => selectLanguage('tr')} className="block px-2 py-1 text-gray-700 cursor-pointer tracking-[0.5px]">Türkçe</div>)
                                    }
                                </div>
                            </div>

                        </div>
                        <div className='tablet:flex hidden ml-0'>
                            <a href='https://www.instagram.com/primusotel/' target='_blank' rel='noopener noreferrer' className='cursor-pointer flex w-[40px] items-center justify-end'>
                                <img
                                    src={InstaIcon}
                                    alt="Instagram Icon"
                                    className='transition-transform w-[22px] scale-110 hover:scale-125 ml-2'
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Header;
