import React, { useEffect } from 'react';

import home0101 from '../assets/images/home/01-01-01-home.webp'
import home0201 from '../assets/images/home/02-01-home.webp'
import home0202 from '../assets/images/home/02-02-home.webp'
import home0301 from '../assets/images/home/03-01-home.webp'
import home0401 from '../assets/images/home/04-01-home.webp'
import home0402 from '../assets/images/home/04-02-home.webp'
import home0403 from '../assets/images/home/04-03-home.webp'
import home0404 from '../assets/images/home/04-04-home.webp'
import home0601 from '../assets/images/home/06-01-home.webp'
import home0602 from '../assets/images/home/06-02-home.webp'
import home0603 from '../assets/images/home/06-03-home.webp'

import { Link } from 'react-router-dom';
import i18next from '../i18n'

import Instagram from '../components/Instagram';

const Home = () => {

    const { t } = i18next;

    /* 4. Container */
    // calculate containers center coordinates
    const calculateBackground = () => {
        const firstContainer = document.getElementById('image-1');
        const lastContainer = document.getElementById('image-4');
        const wrapper = document.getElementById('image-wrapper');

        if (firstContainer && lastContainer && wrapper) {
            const wrapperRect = wrapper.getBoundingClientRect();
            const firstContainerRect = firstContainer.getBoundingClientRect();
            const lastContainerRect = lastContainer.getBoundingClientRect();
            const startY = firstContainerRect.top - wrapperRect.top + firstContainerRect.height / 2;
            const endY = lastContainerRect.top - wrapperRect.top + lastContainerRect.height / 2;
            wrapper.style.background = `linear-gradient(to bottom, #F7F7F7 ${startY}px, #F4EEEB ${startY}px, #F4EEEB ${endY}px, #F7F7F7 ${endY}px)`;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            calculateBackground();
        };

        const handleLoad = () => {
            calculateBackground();
            window.addEventListener('resize', handleResize);
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    /* 4. Container */
    const imageDataContainer4 = [
        {
            id: 'image-1',
            src: home0401,
            title: t("imageDataContainer4_1_Title"),
            subtitle: t("imageDataContainer4_1_Subtitle"),
            description: t("imageDataContainer4_1_Description"),
        },
        {
            id: 'image-2',
            src: home0402,
            title: t("imageDataContainer4_2_Title"),
            subtitle: t("imageDataContainer4_2_Subtitle"),
            description: t("imageDataContainer4_2_Description"),
        },
        {
            id: 'image-3',
            src: home0403,
            title: t("imageDataContainer4_3_Title"),
            subtitle: t("imageDataContainer4_3_Subtitle"),
            description: t("imageDataContainer4_3_Description"),
        },
        {
            id: 'image-4',
            src: home0404,
            title: t("imageDataContainer4_4_Title"),
            subtitle: t("imageDataContainer4_4_Subtitle"),
            description: t("imageDataContainer4_4_Description"),
        },
    ];


    /* 6. Container */
    const imageDataContainer6 = [
        {
            src: home0601,
            title: t("imageDataContainer6_1_Title"),
            subtitle: t("imageDataContainer6_1_Subtitle"),
            description: t("imageDataContainer6_1_Description"),
        },
        {
            src: home0602,
            title: t("imageDataContainer6_2_Title"),
            subtitle: t("imageDataContainer6_2_Subtitle"),
            description: t("imageDataContainer6_2_Description"),
        },
        {
            src: home0603,
            title: t("imageDataContainer6_3_Title"),
            subtitle: t("imageDataContainer6_3_Subtitle"),
            description: t("imageDataContainer6_3_Description"),
        },
    ];

    return (
        <div className='text-[#303030] flex flex-col items-center'>

            {/* 1. Container */}
            <div className='tablet:px-[24.5px] phone:mx-0 overscreen:w-[106rem]'>
                <div>
                    <img className="ml-0 pt-24" src={home0101} alt='view of hotel' />
                </div>
                <div className='phone:mx-[24.5px] tablet:mx-0'>
                    <div>
                        <h2 className='tracking-[0.8px] mt-[20px] font-light tablet:leading-8 phone:leading-7 desktop:text-[40px] tablet:text-[36px] phone:text-[30px] tablet:text-left phone:text-center'>{t("embraceSunshine")}</h2>
                        <h3 className='desktop:text-[24px] tablet:text-[22px] phone:text-[18px] mt-[20px] tablet:leading-7 phone:leading-4 font-extralight'>{t("welcomePrimus")}</h3>
                    </div>
                    <div>
                        <Link to="/about">
                            <h4 className='border-b border-[#9E8772] w-max cursor-pointer text-[18px] leading-[18.85px] font-normal text-[#9E8772] tracking-[0.42px] mt-[20px]'>
                                {t("discoverMore")}
                            </h4>
                        </Link>
                    </div>
                </div>
            </div>

            {/* 2. Container */}
            <div className='px-[24.5px] overscreen:w-[106rem]'>
                <div className='flex flex-col tablet:flex-row mb-10'>
                    <div className='tablet:w-[70%] phone:w-full tablet:mr-8 mx-auto tablet:mx-0 mt-8'>
                        <div>
                            <img className="align-top w-full desktop:h-[38rem] tablet:h-[20rem] object-cover" src={home0201} alt='view of pool' />
                        </div>
                        <div>
                            <h2 className='text-[24px] mt-[20px] leading-6 font-light text-[#1C242E] tracking-[1px] text-center tablet:text-left'>{t("paradiseEye")}</h2>
                            <h3 className='text-[18px] mt-[20px] leading-5 text-[#303030] font-extralight text-left'>{t("primusIsMore")}</h3>
                        </div>
                    </div>
                    <div className='tablet:w-[30%] phone:w-full mx-auto tablet:mx-0 mt-8'>
                        <div>
                            <img className="align-top w-full desktop:h-[38rem] tablet:h-[20rem] phone:h-[20rem] object-cover" src={home0202} alt='view of sustainability' />
                        </div>
                        <div>
                            <h2 className='text-[24px] mt-[20px] leading-6 font-light text-[#1C242E] tracking-[1px] text-center tablet:text-left'>{t("harmonyOf")}</h2>
                            <h3 className='text-[18px] mt-[20px] leading-5 text-[#303030] font-extralight text-left'>{t("experiencePerfect")}</h3>
                        </div>
                    </div>
                </div>
            </div>


            {/* 3. Container */}
            <div className='mt-[35px] mx-0 px-0'>
                <img className='w-[1696px]' src={home0301} alt='view of hotel from away' />
                <div className='flex flex-col items-center text-center'>
                    <h2 className='phone:text-[28px] tablet:text-[30px] desktop:text-[32px] mt-12 font-light text-[#1C242E] leading-[35px] tablet:leading-[45.07px] tracking-[2px] text-center'>
                        {t("journeyDiscover1")} <br /> {t("journeyDiscover2")}
                    </h2>
                </div>
            </div>


            {/* 4. Container */}
            {/* <div id="image-wrapper" className="h-auto w-screen flex justify-center items-center mt-10">
                <div className='px-[24.5px] overscreen:w-[106rem]'>
                    <div className="flex flex-wrap justify-center mt-10">
                        {imageDataContainer4.map((item, index) => (
                            <div key={`image-${index + 1}`} className='flex flex-col items-center text-center tablet:m-6 phone:m-0 desktop:w-[33rem] tablet:w-[30rem] phone:w-[20rem]'>
                                <img id={`image-${index + 1}`} className='w-full object-cover' src={item.src} alt={`view of ${item.title}`} />
                                <div className='tablet:bg-[#FFFFFF] tablet:bg-none px-0 tablet:px-16 phone:px-0 pb-8 h-[250px]'>
                                    <h2 className='text-[12px] mt-4 pb-2 font-light text-[#1C242E] leading-[14.66px] tracking-[2px] text-center uppercase'>
                                        {item.title}
                                    </h2>
                                    <h2 className='text-[22px] font-light pb-2 text-[#303030] leading-[26px] tracking-[0.98px] text-center'>
                                        {item.subtitle}
                                    </h2>
                                    <h2 className='text-[14px] font-light text-[#303030] leading-[18px] tracking-[0.8px] text-center'>
                                        {item.description}
                                    </h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}


            {/* 5. Container */}
            {/* <div className="h-auto w-screen bg-[#1C242E] flex justify-center items-center mt-10">
                <div className='text-[#F7F7F7] tablet:p-8 phone:p-0 tablet:text-left phone:text-center overscreen:w-[106rem] '>
                    <div className='flex tablet:flex-row tablet:justify-between items-center phone:justify-center phone:flex-col'>
                        <div className='tablet:w-[100%] tablet:mr-10 phone:w-[100%] phone:m-0 phone:flex phone: justify-center'>
                            <img className="tablet:pt-6 pb-6 phone:pt-[30px] phone:px-[16px]" src={home0501} alt='sharz empowering future' />
                        </div>
                        <div className='w-[80%] desktop:ml-10 desktop:pr-24 tablet:ml-0 tablet:pr-0'>
                            <h2 className='tablet:text-[40px] phone:text-[35px] tracking-1 font-light mb-4 leading-[45px]'>
                                {t("empoweringJourney")} <br />{t("empoweringJourney2")}
                            </h2>
                            <h3 className='text-xl font-extralight mb-8 leading-[25px]'>
                                {t("atPrimus")}
                                <span className='desktop:inline tablet:hidden phone:inline'>
                                    {t("valuedGuest")}
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className='desktop:hidden tablet:inline phone:hidden mb-8 mr-24'>
                        <h3 className='text-xl font-extralight leading-[25px]'>
                            {t("valuedGuest")}
                        </h3>
                    </div>
                </div>
            </div> */}


            {/* 6. Container */}
            <div className='px-[24.5px] mt-[20px] tablet:mt-[150px]'>
                <h2 className="text-[#1C242E] text-[26px] font-light tracking-[2px] tablet:mb-[50px] phone:mb-[5px] text-center">{t("discoverMore")}</h2>
                <div className="flex flex-wrap justify-center text-center tablet:text-left">
                    {imageDataContainer6.map((item, index) => (
                        <div key={`discover-image-${index}`} className='flex flex-col items-center m-4 desktop:w-[26rem] tablet:w-[32rem] phone:w-full mb-4'>
                            <img className='object-cover phone:aspect-square' src={item.src} alt={`view of ${item.title}`} />
                            <div className='phone:pb-8 tablet:pb-0 h-[230px] tablet:h-[200px] phone:h-[230px]' >
                                <h2 className='text-[14px] mt-5 mb-2 font-light text-[#1C242E] leading-[14.66px] tracking-[2px] uppercase'>
                                    {item.title}
                                </h2>
                                <h2 className="text-[#303030] mb-2 text-[20px] font-light leading-6 tracking-[0.98px] font-spartan">
                                    {item.subtitle}
                                </h2>
                                <h2 className="text-[#303030] text-[16px] font-light leading-[15px] tracking-[0.8px]">
                                    {item.description}
                                </h2>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* 7. Container */}
            <Instagram />

        </div>
    )
}

export default Home